<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>What we do</h2>

        </div>
    </div>
</div>

<!-- Start new Services Area -->
<section class="how-it-works-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Three Principled Approach
            </h2>
            <div class="bar"></div>            
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works"><img src="assets/img/how-it-works-image/1.png" alt="image">
                    <h4>1. Commercial</h4>
                    <p>
                    <ul>
                        <li>We don’t believe in making unnecessary demands & will always try to gauge risk based on the
                            actual scenario.</li>
                        <li>Our corporate trustee experience enables us to take a flexible approach e.g. relating to
                            indemnities & outside counsel.</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works"><img src="assets/img/how-it-works-image/2.png" alt="image">
                    <h4>2. Expeditious</h4>
                    <p>
                    <ul>
                        <li>We use an established network of advisors & employ experts only where needed whilst leading
                            the transactions & effectively managing costs ourselves.</li>
                        <li>Advisors should not be used to front nor should they run a transaction.</li>

                    </ul>

                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works"><img src="assets/img/how-it-works-image/3.png" alt="image">
                    <h4>3. Responsive</h4>     
                    <p>
                        <ul>
                            <li>We pride ourselves on quick and decisive decision making, providing highly efficient
                                services in the corporate trustee market place.</li>
                            <li>We do not believe in a traditional sales, relationship management & operations organisation
                                model.</li>
                        </ul>
                              
                    
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End  new Services Area -->
<!-- Start Features Area -->
<section class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Our 360<sup><span style="font-size: medium;">o</span></sup> Distressed Debt Offering</h2>
            <div class="bar"></div>
            <p>Proud to offer clients.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-settings"></i>
                    </div>

                    <h3><a href="/services">Operational Advisory</a></h3>
                    
                    <ul class="feature-ul">
                        <li>Optimal operational structure & set-up</li>
                        <li>Maximise investor participation</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-envelope-of-white-paper"></i>
                    </div>
                    <h3><a href="/services">Investor Identification</a></h3>
                    <ul class="feature-ul">
                        <li>Geographical & custodian breakdowns</li>
                        <li>Holder identification</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-menu"></i>
                    </div>
                    <h3><a href="/services">Solicitation</a></h3>
                    <ul class="feature-ul">
                        <li>Information Agent & tabulation services</li>
                        <li>Consent solicitations & investor meetings</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-cursor"></i>
                    </div>

                    <h3><a href="/services">Incumbent Agents</a></h3>
                    <ul class="feature-ul">
                        <li>Replacement Bond Trustee</li>
                        <li>Replacement Security & Facility Agent</li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-alarm"></i>                                                
                    </div>

                    <h3><a href="/services">Completion & Execution</a></h3>
                    <ul class="feature-ul">
                        <li>Exchange/Tender Agent</li>
                        <li>Settlement services</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-cursor"></i>
                    </div>
                    <h3><a href="/services">Enforcement & Restructuring</a></h3>
                    <ul class="feature-ul">
                        <li>Proactive enforcement</li>
                        <li>Local & cross border litigation/arbitration</li>
                    </ul>
                </div>
            </div>            

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-envelope-of-white-paper"></i>
                    </div>

                    <h3><a href="/services">Post-Closing</a></h3>
                    <ul class="feature-ul">
                        <li>Escrow arrangements</li>
                        <li>Holding period trustee</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-info"></i>
                    </div>

                    <h3><a href="/services">New Issuance</a></h3>
                    <ul class="feature-ul">
                        <li>New Bond Trustee</li>
                        <li>New Security/Facility Agent</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<section class="invoicing-area ptb-70">
    <app-funfact></app-funfact>
</section>
<!-- End Fun Facts Area -->
<app-feedback></app-feedback>
<app-talk-button></app-talk-button>
<app-partner></app-partner>
<app-download></app-download>