<!-- Start Page Title Area -->

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms and Conditions</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70" style="margin-top: -70px;">
    <div class="container">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <!-----start event------->
                <div class="row" style="margin-bottom: -50px;">
                    <div class="col-lg-12 col-md-12">
                        <div class="blog-details">
                            <div class="article-content">
                               
                                <p>Welcome to our website. The following Terms of Use together with our GDPR Privacy
                                    Policy governs the relationship between i2 Capital Markets Ltd, under company number
                                    10426778 and you in relation to this website.</p>

                                  <p>If you continue to browse and use this website, you are agreeing to comply with and be
                                    bound by the following terms and conditions of use. If you disagree with any part of these
                                    terms and conditions, please do not use our website.</p>
                                    <p>The term ‘i2 Capital Markets Ltd’, ‘i2 Capmark or ‘us’ or ‘we’ refers to i2 Capital Markets Ltd,
                                        the owner of the website, whose registered office is Kemp House, 160 City Rd, London,
                                        EC1V 2NX, United Kingdom.</p>  
                                        <p>The term ‘you’ refers to the user or viewer of our website. The use of this website is subject
                                            to the following terms of use:
                                        </p>
                                    
                                      <ul>
                                          <li>
                                            The content of the pages of this website is for your general information and use only.
                                            It is subject to change without notice
                                          </li>
                                          <li>
                                            Neither we nor any third parties provide any warranty or guarantee as to the
                                            accuracy, timeliness, performance, completeness or suitability of the information and
                                            materials found or offered on this website for any particular purpose. You
                                            acknowledge that such information and materials may contain inaccuracies or errors
                                            and we expressly exclude liability for any such inaccuracies or errors to the fullest
                                            extent permitted by law
                                        </li>
                                        <li>
                                            Your use of any information or materials on this website is entirely at your own risk
                                            and we shall not be liable for the consequences of such use. It shall be your own
                                            responsibility to ensure that any products, services or information available through
                                            this website meet your specific requirements
                                        </li>
                                        <li>
                                            This website contains material which is owned by or licensed to us. This material
includes, but is not limited to, the design, layout, look, appearance and graphics.
Reproduction is prohibited other than in accordance with the copyright notice, which
forms part of these terms and conditions  
                                        </li>
                                        <li>
                                            All trademarks reproduced in this website which are not the property of, or licensed
to, the operator are acknowledged on the website
                                              
                                        </li>
                                        <li>
                                            Unauthorised use of this website may give rise to a claim for damages and/or be a
                                            criminal offence
                                        </li>
                                        <li>
                                            From time to time this website may also include links to other websites. These links
                                            are provided for your convenience to provide further information. They do not signify
                                            that we endorse the websites. We have no responsibility for the content of the linked
                                            websites
                                        </li>
                                        <li>
                                            Your use of this website and any dispute arising out of such use of the website is
subject to the laws of England
                                        </li>
                                      </ul> 
                                      
                            </div>
                        </div>
                    </div>
                </div>
                <!---------end--->
            </div>

        </div>
    </div>
</section>