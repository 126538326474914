import { Component, OnInit,AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import{ GlobalConstants } from '../../common/GlobalConstants';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as forge from 'node-forge';
declare var $:any;

const httpOptions = {
  headers: new HttpHeaders({
    // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Authorization': 'ApiAuth xyz'
  })
}


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  username: any;
  //email: any;
  mobile: any;
  password:any;
  organization:any;
  TC: boolean;
  cb:any;
  countryDetailsData:any;
  countryDetails:any;
  countryCode:any;
  @ViewChild('myModal', {static : true}) myModal:ElementRef;

  constructor(private http: HttpClient, private route: ActivatedRoute,private router: Router,private formBuilder: FormBuilder) { }
  signUpData: FormGroup;
  submitted=false;
  showError=false;
 msg:any;
  ngOnInit() {
    this.countryList();
    this.showError=false;
    this.signUpData = this.formBuilder.group({
      name: ['',Validators.required],
      email:['',[Validators.required, this.emailValidator]],
      //email: ['',Validators.required,Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      mobile: ['',[Validators.required, this.numberValidator]],
     // organization: ['',Validators.required],
     organization: [''],
      password: ['',[Validators.required,Validators.minLength(8)]],
      cb: [false, Validators.requiredTrue],
      countryCode: ['']
    

    
    });
    this.TC=false;
  }
  
  get f(){
    return this.signUpData.controls;
  }
  
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  countryList():any {
  	this.http.get(GlobalConstants.apiURL+'countryCodeList.php', httpOptions).subscribe(response => {      
     // console.log(response);
      this.countryDetailsData = response;
      this.countryDetails = this.countryDetailsData.data;
      console.log('Details', this.countryDetails);
     // this.holdingNumberArr = this.eventDetails.holdingNumber.split(',');
    })
  }
  mobileValidator(control)
{
  if (control.value) {
    const matches = control.value.match(/^\+?([+][0-9]{1,5}[ ])\)?[-. ]?([0-9]{4,16})$/);
   // const matches = control.value.match(/^\+?([+][0-9]{1,5})\)?[-. ]?([0-9]{4,16})$/);
  // const matches = control.value.match(/^[+]\\([0-9])?[- ]$/);
    return matches ? null : { 'invalidNumber': true };
  } else {
    return null;
  }
}  

numberValidator(control)
{
  if (control.value) {
   const matches = control.value.match(/^[0-9]+$/);
   //const matches = control.value.match(/^[0-9]*\.?[0-9]*$/g);
    return matches ? null : { 'invalidNumber': true };
  } else {
    return null;
  }
}  
  onSubmit() {
    var rsa=forge.pki.publicKeyFromPem(GlobalConstants.publicKey);
          this.submitted=true;
          if(this.signUpData.invalid)
          {
             return;
          }
          else
          {
           //alert($("#countryCode").val()+' '+this.signUpData.get('mobile').value);
            const formdata = new FormData();
            formdata.append('name', window.btoa(rsa.encrypt(this.signUpData.get('name').value)));
            formdata.append('email', window.btoa(rsa.encrypt(this.signUpData.get('email').value)));
           formdata.append('mobile', window.btoa(rsa.encrypt(this.signUpData.get('mobile').value)));
           formdata.append('countryCode',window.btoa(rsa.encrypt($("#countryCode").val())));
           // formdata.append('mobile', $("#countryCode").val()+' '+this.signUpData.get('mobile').value);
            formdata.append('organization', window.btoa(rsa.encrypt(this.signUpData.get('organization').value)));
            formdata.append('password', window.btoa(rsa.encrypt(this.signUpData.get('password').value)));
            this.http.post<any>(GlobalConstants.apiURL+'signup.php', formdata).subscribe(response => {
            if(response.error==false)
            {
              
               this.showError=true;
               this.msg=response.message;
               this.myModal.nativeElement.click();
            }
            else if(response.error==true)
            {
              
                $("#name").val("");
                $("#email").val("");
                $("#mobile").val("");
                $("#organization").val("");
                $("#password").val("");
                $("#code_1").val("");
                $("#cb"). prop("checked", false);
                 this.showError=false;

               this.myModal.nativeElement.click();
               
    
            }
              
            });
        

          }
  

  }
  
  
  onCountryChange(event)
  {
    $("#code_1").val("+"+event.dialCode);
  }
  goToPage(){
    this.router.navigate(['/signin']);
  }
  
 /* postRegisterData():any {
    let jsonData = new HttpParams()
    .set('name', this.username)
    .set('email', this.email)
    .set('mobile', this.mobile)
    .set('password', this.password);
    
  	this.http.post(GlobalConstants.apiURL+'signup.php',jsonData).subscribe(response => {
      console.log(response,'data');      
      this.router.navigate(['/signin']);
    });
  }*/

}