<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">        

        <div class="partner-inner">
            <div class="row align-items-center">
                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Bonds & Loans</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>High Yield</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Structured Products</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6" routerLink="/services">
                    <a href="javascript:retun false;">
                        <span><h6><b>Trustee & Security Agent</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Cross-border Insolvency</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Liability Management</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Restructuring</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Enforcement</b></h6></span>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-4 col-md-3 col-6">
                    <a href="javascript:retun false;" routerLink="/services">
                        <span><h6><b>Dispute Resolution</b></h6></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->
