<!-- Start Page Title Area -->

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70" style="margin-top: -70px;">
    <div class="container">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <!-----start event------->
                <div class="row" style="margin-bottom: -50px;">
                    <div class="col-lg-12 col-md-12">
                        <div class="blog-details">
                            <div class="article-content">
                                <h4>GDPR Privacy Policy</h4>
                                <p>This privacy policy notice sets out how i2 Capital Markets Ltd providing Corporate Trustee
                                    and other specialist agency services, under company number 10426778, uses and protects
                                    any information that you give us including when you use our website and apps;
                                    www.i2capmark.com</p>
                                <p>The purpose of this policy is to explain to you how we control, process, handle and protect
                                    your personal information including while browsing or using this website, including your
                                    rights under current laws and regulations. If there are any terms in this privacy policy that
                                    you do not agree with, please discontinue use of our site, our apps and our services.</p>
                                <p>Should we ask you to provide certain information by which you can be identified when using
                                    this website, you can be assured that it will only be used in accordance with this privacy
                                    statement.</p>
                                <p>By accessing and using this site, you hereby agree to the terms of this privacy policy.
                                </p>

                                <h4>Personal Information</h4>   
                                <p>In order to respond to your enquiries, fulfil your requests or manage your interactions with
                                    us via our website and apps, we may collect personal information that you voluntarily
                                    provide to us such as name, address, contact information, passwords and security
                                    credentials and other certain information.</p>
                                <p> In addition to the personal information that you may provide us, this website may use
                                    technology that collects certain technical information like your IP address, your computer’s
                                    operating system, your browser type, traffic patterns and the address of any referring web
                                    sites. Information collected via our apps include your geo-location, mobile device, push
                                    notifications, and social media app permissions.
                                    </p>
                                <p>We may request access or permission to certain features from your mobile device,
                                    including your mobile device’s Bluetooth, calendar, camera, contacts, microphone,
                                    reminders, sensors, SMS messages, social media accounts, storage, and other features. If
                                    you wish to change our access or permissions, you may do so in your device’s settings. We
                                    may automatically collect device information such as your mobile device ID, model and
                                    manufacturer, operating system, version information and IP address</p>
                                <p>We may request to send you push notifications regarding your account or the mobile
                                    application. If you wish to opt-out from receiving these types of communications, you may
                                    turn them off in your device’s settings.</p>
                                <p>The personal data we hold on you is available upon request by contacting us via our
                                    contact form on our website. You can request that your data is updated and/or deleted at
                                    any time, unless we can justify that it is retained for legitimate business and/or legal
                                    purposes. When updating your personal data, you may be asked to verify your identity
                                    before your request can be actioned.</p>

                                 <h4>Usage of Personal Data </h4>   
                                 <p>We process your information for purposes based on legitimate business interests, the
                                    fulfilment of our contract with you, compliance with our legal obligations, and/or your
                                    consent. We may use the provided information to understand your needs and provide you
                                    with a better service, and in particular for the following reasons:</p>    
                                <ul>
                                    <li>Respond to your questions and any other requests                                    </li>
                                    <li>Process such information to comply with our obligations under our agency
                                        transactions and to maintain such records </li>
                                    <li>Pass to another organisation to supply/deliver products or services you have
                                        requested  </li>
                                    <li>Carry out our obligations arising from any contracts entered into by us
                                    </li>
                                    <li>Comply with any legal requirements</li>
                                    <li>We may use third parties to carry out certain activities, such as processing and
                                        sorting data, monitoring how customers use our site and issuing our emails for us</li>
                                    <li>Obtain your views or comments to improve our products and services</li>
                                    <li>Notify you of changes to our business and any related services</li>
                                    <li>Send you communications which you have requested and that may be of interest to
                                        you. These may include information about service and product updates, newsletters,
                                        and events for example</li>
                                    <li>Internal record keeping</li>
                                </ul>
                                <p>We will not provide this information to a third party without your permission, except when
                                    necessary to process your order, fulfil your requests or manage any provided solutions. </p>
                                 <h4>How long do we keep your Data for?</h4> 
                                 <p>We keep your information for as long as we have reasonable business needs and many
                                    records may legitimately need to be stored for more than six years after receipt. We will
                                    retain the personal data in line with legal and regulatory requirements or guidance to fulfil
                                    the purposes outlined in this privacy policy unless otherwise required by law.</p>
                                <h4>Security, Storage and Transfers</h4>  
                                <p>We are committed to ensuring that your information is secure but there are always risks
                                    associated with providing personal data, whether in person, by phone or electronically and
                                    no system of technology is completely safe. </p>
                                <p>In order to prevent unauthorised access or disclosure, we have put in place suitable
                                    physical, electronic and appropriate procedures to safeguard and secure the information we
                                    collect. </p>
                                <p>Although we will do our best to protect your personal information, transmission of personal
                                    information to and from our sites and apps is at your own risk. You should only access the
                                    services within a secure environment.</p>
                                <p>We may transfer, store, and process your information in countries other than your own. Our
                                    servers are located in the United Kingdom and if you are accessing our website and apps
                                    from outside the United Kingdom, please be aware that your information may be transferred
                                    to, stored, and processed by us in our facilities and by those third parties with whom we
                                    may share your personal information, in the United Kingdom and other countries. </p>
                                <p>If you are a resident in the European Economic Area, then these countries may not have
                                    data protection or other laws as comprehensive as those in your country. We will however
                                    take all necessary measures to protect your personal information in accordance with this
                                    privacy policy and applicable law.</p>
                                <p>We only share information with your consent, to comply with laws, to protect your rights, or
                                    to fulfil business obligations.
                                    </p>
                                <h4>Accuracy of Collected Data
                                </h4>  
                                <p>The accuracy of personal data is imperative. We will on our own initiative, or at your
                                    request, replenish, rectify, or erase any incomplete, inaccurate, or outdated personal data
                                    retained by us in connection with the operation of this website and our apps. 
                                    </p>
                                   <h4> Visitor Identification</h4>
                                    <p>From time to time, information may be placed on your computer to allow us to identify you.
                                    This information is commonly known as “cookies”. The use of cookies is an industry
                                    standard and many web sites use them. Overall, cookies help us provide you with a better
                                    website, by enabling us to monitor which pages you find useful and which you do not. A
                                    cookie in no way gives us access to your computer or any information about you, other than
                                    the data you choose to share with us.</p>
                                    <p>You can choose to accept or decline cookies. Most web browsers automatically accept
                                        cookies, but you can usually modify your browser setting to decline cookies if you prefer.
                                        This may prevent you from taking full advantage of the website.
                                        </p>
                                      <h4>Links to other websites
                                    </h4>   
                                    <p>Our website may contain links to other websites of interest. However, once you have used
                                        these links to leave our site, we are not responsible for the protection and privacy of any
                                        information that you provide whilst visiting such sites and such sites are not governed by
                                        this privacy statement. You should exercise caution and look at the privacy statement
                                        applicable to the website in question. </p>
                                   <h4>16 or Under</h4>
                                   <p>We are concerned to protect the privacy of children aged 16 or under. If you are aged 16 or
                                    under‚ please get your parent/guardian's permission beforehand whenever you provide us
                                    with personal information. </p> 
                                    <h4>Your Rights</h4>
                                    <p>If you have questions or comments about this policy, email us at info@i2capmark.com or by
                                        post to:</p>
                                        
                                        <p>
                                           <span>Data Protection Officer,</span><br>
                                           <span> i2 Capital Markets Ltd,</span><br>
                                            <span> Kemp House,</span><br>
                                            <span>160 City Rd, London,</span><br>
                                            <span>EC1V 2NX,</span><br>
                                            <span> United Kingdom</span>
                                        </p>    
                                   <h4>Changes</h4> 
                                   <p>We may change this privacy policy, or change, modify or withdraw access to this website,
                                    or the content of these pages at any time with or without notice.</p>    
                            </div>
                        </div>
                    </div>
                </div>
                <!---------end--->
            </div>

        </div>
    </div>
</section>