<!-- Start Main Banner Area -->
<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1 style="max-width: 100%;">Corporate Trustee & Agency Services</h1>
                    <p style="max-width: 650px;">
                        Transforming debt capital markets administration into an execution-driven practice
                    </p>
                    <a href="/contact" class="btn btn-primary">Get In Touch</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Boxes Area -->
<section class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>

                        <h3>Commercial</h3>
                        <p style="font-size: 16px;">Experience and expertise led</p>

                        <a href="services" class="read-more-btn">Read More</a>
                    </div>
                </div>

                <!--  <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>

                        <h3>Fully Encrypted</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>

                        <a href="features-1.html" class="read-more-btn">Read More</a>
                    </div>
                </div> -->

                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="flaticon-wallet"></i>
                        </div>

                        <h3>Expeditious</h3>
                        <p style="font-size: 16px;">An established network of external advisors</p>

                        <a href="services" class="read-more-btn">Read More</a>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-shield"></i>
                        </div>

                        <h3>Responsive</h3>
                        <p style="font-size: 16px;">Quick and decisive decision making</p>

                        <a href="services" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Boxes Area -->

<section class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Diverse range of specialist agency services</h2>
                    <div class="bar"></div>
                    <p style="font-size: 16px;">Highly experienced across all DCM distressed asset classes and
                        cross-border work-out situations across the following areas.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Bonds & Loans</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Restructuring</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Structured Products</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Enforcement</span></li>
                        <li><span><i class="flaticon-check-mark"></i> ABS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Dispute Resolution</span></li>
                        <li><span><i class="flaticon-check-mark"></i> High Yield</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Emerging Markets</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Liability Management</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Covered Bonds</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Operational Issues</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cross-border Insolvency</span></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image"><img
                            src="assets/img/invoicing-image/1.png" data-wow-delay="0.7s" alt="image" class="wow zoomIn"
                            style="visibility: visible; animation-delay: 0.7s; animation-name: zoomIn;">
                        </div>
                    <div class="main-mobile-image"><img
                            src="assets/img/invoicing-image/main-pic.png" data-wow-delay="0.7s" alt="image"
                            class="wow zoomIn" style="visibility: hidden; animation-delay: 0.7s; animation-name: none;">
                    </div>
                    <div class="circle-image"><img
                            src="assets/img/invoicing-image/circle1.png" alt="image"><img
                            src="assets/img/invoicing-image/circle2.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Features Area -->
<section class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Our 360<sup><span style="font-size: medium;">o</span></sup> Distressed Debt Offering</h2>
            <div class="bar"></div>
            <p>Proud to offer clients.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-settings"></i>
                    </div>

                    <h3><a href="/services">Operational Advisory</a></h3>

                    <ul class="feature-ul">
                        <li>Optimal operational structure & set-up</li>
                        <li>Maximise investor participation</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-envelope-of-white-paper"></i>
                    </div>
                    <h3><a href="/services">Investor Identification</a></h3>
                    <ul class="feature-ul">
                        <li>Geographical & custodian breakdowns</li>
                        <li>Holder identification</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-menu"></i>
                    </div>
                    <h3><a href="/services">Solicitation</a></h3>
                    <ul class="feature-ul">
                        <li>Information Agent & tabulation services</li>
                        <li>Consent solicitations & investor meetings</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-cursor"></i>
                    </div>

                    <h3><a href="/services">Incumbent Agency</a></h3>
                    <ul class="feature-ul">
                        <li>Replacement Bond Trustee</li>
                        <li>Replacement Security & Facility Agent</li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-alarm"></i>
                    </div>

                    <h3><a href="/services">Completion & Execution</a></h3>
                    <ul class="feature-ul">
                        <li>Exchange/Tender Agent</li>
                        <li>Settlement services</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-cursor"></i>
                    </div>
                    <h3><a href="/services">Enforcement & Restructuring</a></h3>
                    <ul class="feature-ul">
                        <li>Proactive enforcement</li>
                        <li>Local & cross border litigation/arbitration</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-envelope-of-white-paper"></i>
                    </div>

                    <h3><a href="/services">Post-Closing</a></h3>
                    <ul class="feature-ul">
                        <li>Escrow arrangements</li>
                        <li>Holding period trustee</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-info"></i>
                    </div>

                    <h3><a href="/services">New Issuance</a></h3>
                    <ul class="feature-ul">
                        <li>New Bond Trustee</li>
                        <li>New Security/Facility Agent</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Fun Facts Area -->
<section class="invoicing-area ptb-70">
    <app-funfact></app-funfact>
</section>
<!-- End Fun Facts Area -->
<app-feedback></app-feedback>
<app-talk-button></app-talk-button>
<app-partner></app-partner>
<app-download></app-download>

<!-- Start Account Create Area -->
<app-account-button></app-account-button>
<!-- End Account Create Area -->