<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-12">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a href="#"><img src="assets/img/klob_logo.webp" alt="logo" width="180px"></a>
                        <p>Bonds | Loans | High Yield | Structured Products | Trustee | Security Agent | Restructuring | Enforcement | Dispute Resolution</p>
                    </div>

                    <ul class="social-links">
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li> -->
                        <li><a href="https://www.instagram.com/i2capmark/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://uk.linkedin.com/company/i2-capital-markets" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Company</h3>
                    <ul class="list">
                        <li><a href="about-us">About Us</a></li>
                        <li><a href="services">Services</a></li>
                        <li><a href="policy">Privacy Policy</a></li>                        
                        <li><a href="policyterms">T&C</a></li>                        
                        <li><a href="contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>           

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><span>Office:</span> 128 City Rd, London, EC1V 2NX, United Kingdom

                        </li>
                        <li><span>Email:&nbsp;</span> <a href="mail:info@i2capmark.com">info@i2capmark.com</a></li>                       
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>Copyright @2022 <a href="#">i2capmark</a>. All rights reserved</p>
        </div>
    </div>

    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
