import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './demos/default/default.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { TeamComponent } from './pages/team/team.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TransactionComponent } from './pages/Transaction/transaction.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ActivationComponent } from './pages/activation/activation.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { TermsconditionComponent } from './pages/termscondition/termscondition.component';
import { PolicytermsComponent } from './pages/policyterms/policyterms.component';
import { EventPreviewComponent } from './pages/event-preview/event-preview.component';


import * as $ from 'jquery';

const routes: Routes = [
    {path: '', component: DefaultComponent},
    {path: 'about-us', component: AboutComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'team', component: TeamComponent},   
    {path: 'faq', component: FaqComponent}, 
    {path: 'policyterms', component: PolicytermsComponent}, 
    {path: 'signup', component: SignupComponent},
    {path: 'signin', component: SigninComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'transactions/:page', component: TransactionComponent},   
    {path: 'event-details/:id/:type/:name', component: EventDetailsComponent},
    {path: 'policy', component: PolicyComponent},
    {path: 'activation/:user_id', component: ActivationComponent},
    {path: 'forgotpassword/:id/:time', component: ForgotpasswordComponent},
    {path: 'termscondition/:id', component: TermsconditionComponent},
    {path: 'event-preview/:id', component: EventPreviewComponent},
    {path: '**', component: NotFoundComponent}

    

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
