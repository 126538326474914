import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import{ GlobalConstants } from '../../common/GlobalConstants';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'ApiAuth xyz'
  })
}
@Component({
  selector: 'app-termscondition',
  templateUrl: './termscondition.component.html',
  styleUrls: ['./termscondition.component.scss']
})
export class TermsconditionComponent implements OnInit {
  eventDetails:any;
  filePath: any;

  constructor(private http: HttpClient, private router: Router,private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.filePath=GlobalConstants.apiURL+'uploads/'

    this.getEventList(this.activatedRoute.snapshot.params['id']);

  }
  
  getEventList(id:any):any {
  	this.http.get(GlobalConstants.apiURL+'GetTermsCondition.php?id='+id, httpOptions).subscribe(response => {      
      this.eventDetails=response;

      console.log(response);
    })
  }

}
