<!-- Start Page Title Area -->


<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70" style="margin-top: 100px;">
    <div class="container">
       <div style="text-align: center;">
           <h3>Welcome To i2 CAPITAL MARKETS</h3>
           <div *ngIf="submittedResetPassword and submittedResetPassword==true" style="color:red">{{msg}}</div>
           <div class="row">
            <div class="col-lg-3 col-md-3 p-0">
            </div>
            <div class="col-lg-6 col-md-6 p-0">
                <form  [formGroup]="resetPasswordData" (ngSubmit)="ForgotPasswordSubmit()">
                    <div class="form-group">
  
                        <input type="password" id="password" formControlName="password" placeholder="Create a Password" class="form-control" [ngClass]="{ 'is-invalid': submittedResetPassword && f.password.errors }" />
                        <div *ngIf="f.password.errors" class="invalid-feedback" style="color:red;text-align: left;">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters</div>
                        </div>
                    </div>
                     <button type="submit" class="btn btn-primary">Reset Your Password</button>                                       
                                               
                                                    
                </form>
            </div>
            <div class="col-lg-3 col-md-3 p-0">
            </div>

           </div>
           
          
       </div>

    </div>
</section>