import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from '../../common/GlobalConstants';
import { TmplAstRecursiveVisitor } from '@angular/compiler';
import * as forge from 'node-forge';

declare var $:any;
const httpOptions = {
  headers: new HttpHeaders({
    // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Authorization': 'ApiAuth xyz'
  })
}

@Component({
  selector: 'app-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss']
})
export class EventPreviewComponent implements OnInit {
  eventDetailsData: any;
  eventDetails: any;
  documentDetails: any;
  filePath: any;
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getEventDetails();
    this.filePath = GlobalConstants.apiURL + 'uploads/'
  }
  DownloadFile(filename) {
    var element = document.createElement('a');
    element.setAttribute('href', GlobalConstants.apiURL + 'downloadFile.php?eventId=' + this.activatedRoute.snapshot.params['id'] + '&userid=' + sessionStorage.getItem('userId') + '&fileName=' + filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  /******Event Details Data******/
  getEventDetails(): any {
  
     this.http.get(GlobalConstants.apiURL + 'eventPreview.php?id=' + this.activatedRoute.snapshot.params['id'], httpOptions).subscribe(response => {
      this.eventDetailsData = response;
      this.eventDetails = this.eventDetailsData.eventDetails;
      this.documentDetails = this.eventDetailsData.documentDetails;
      console.log(response);
    })
   
  }

}
