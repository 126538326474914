import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultComponent } from './demos/default/default.component';
import { FeedbackComponent } from './common/feedback/feedback.component';
import { FunfactComponent } from './common/funfact/funfact.component';
import { AboutComponent } from './pages/about/about.component';
import { PartnerComponent } from './common/partner/partner.component';
import { ServicesComponent } from './pages/services/services.component';
import { DownloadComponent } from './common/download/download.component';
import { AccountButtonComponent } from './common/account-button/account-button.component';
import { TalkButtonComponent } from './common/talk-button/talk-button.component';
import { TeamComponent } from './pages/team/team.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TransactionComponent } from './pages/Transaction/transaction.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { ActivationComponent } from './pages/activation/activation.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { TermsconditionComponent } from './pages/termscondition/termscondition.component';
import { PolicytermsComponent } from './pages/policyterms/policyterms.component';
import { EventPreviewComponent } from './pages/event-preview/event-preview.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    FeedbackComponent,
    FunfactComponent,
    AboutComponent,
    PartnerComponent,
    ServicesComponent,
    DownloadComponent,
    AccountButtonComponent,
    TalkButtonComponent,
    TeamComponent,
    NotFoundComponent,
    FaqComponent,
    SignupComponent,
    SigninComponent,
    ContactComponent,
    TransactionComponent,
    EventDetailsComponent,
    ActivationComponent,
    ForgotpasswordComponent,
    TermsconditionComponent,
    PolicytermsComponent,
    EventPreviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule
 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
