<!-- Start Login Area -->
<section class="login-area">
    
    

    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
               
            <button [hidden]="true" #myModal data-toggle="modal" data-target="#myModal">open modal</button>
                <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">Login</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div >
                                <span *ngIf="this.showError==true" style="color:red;">{{msg}}</span>  
                                <span *ngIf="this.showError==false" style="color:green;">{{msg}}</span>  

                            </div>
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
                       
                        </div>
                    </div>
                    </div>
                </div>
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a href="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>


                            <h3>Welcome</h3>
                            <p>New to i2capmark? <a href="/signup"><b><u>Sign up</u></b></a></p>
                            <!--<div class="form-group" *ngIf="this.showError==true">
                                <label class="alert alert-danger" name="msg">{{msg}}</label>
                            </div>-->
                           
                            <!--<form *ngIf="showLogin" (ngSubmit)="postLoginData()" #heroForm="ngForm">-->
                                <form *ngIf="showLogin" [formGroup]="signInData" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input type="text" placeholder="Insert Email ID" name="email" id="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                   <div *ngIf="submittedLogin && f.email.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.email.errors.required" style="color:red;text-align: left;">Email is required</div>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submittedLogin && f.email.invalid && (f.email.dirty || f.email.touched)" style="color:red;text-align: left;">Please enter valid email</div>
                                
                                   
                                </div>
                                                         
                                <div class="form-group">
                                    <input type="password" formControlName="password" placeholder="Create a Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <div *ngIf="submittedLogin && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required" style="color:red;text-align: left;">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength" style="color:red;text-align: left;">Password must be at least 8 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary">Login</button>
                                </div>                               
                                
                                <div class="forgot-password">
                                    <a style="cursor: pointer;" (click)="ForgotPassword()">Forgot Password?</a>
                                </div>                                
                            </form>
                            <form *ngIf="!showLogin" [formGroup]="resetPasswordData" (ngSubmit)="ForgotPasswordSubmit()">
                                <div class="form-group">
                                    <input type="text" placeholder="Insert Email ID" name="email_1" id="email_1" formControlName="email_1" class="form-control" [ngClass]="{ 'is-invalid': submittedResetPassword && resetForm.email_1.errors }" />
                                   <div *ngIf="submittedResetPassword && resetForm.email_1.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="resetForm.email_1.errors.required" style="color:red;text-align: left;">Email is required</div>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submittedResetPassword && resetForm.email_1.invalid && (resetForm.email_1.dirty || resetForm.email_1.touched)" style="color:red;text-align: left;">Please enter valid Email</div>
                                
                                   
                                </div>
								 <button type="submit" class="btn btn-primary">Reset Your Password</button>                                       
                                
                                <div class="forgot-password">
                                    <a style="cursor: pointer;" (click)="Login()">Login</a>
                                </div>                                
                                                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->
