import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AutoLogoutService } from '../common/autologout.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],

    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    location: any;
    layoutClass: string;
    loggin = false;
    test='Angular';
    constructor(
        private router: Router,
        location: Location,private service: AutoLogoutService
    ) {
        this.test = service.val;
        this.loggin = sessionStorage.getItem('name') ? true : false;
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.location = location.path();
                if (this.location == '/demo-3') {
                    this.layoutClass = 'navbar-style-two';
                } else {
                    this.layoutClass = '';
                }
            }
        });
    }

    doLogout() {
        delete sessionStorage.name;
        delete sessionStorage.userId;
        this.router.navigate(['/signin']);        
    }
    ngOnInit() {
    }

}
