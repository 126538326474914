<!-- Start Account Create Area -->
<section class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2 style="text-transform: unset;">i2 Investor Cloud Platform
                </h2>
            <p>Secure online participation - the smarter option!</p>
            <a href="/services" class="btn btn-primary">Find Out More</a>
        </div>
    </div>
</section>
<!-- End Account Create Area -->
