<!-- Start Feedback Area -->
<section class="feedback-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>The LIBOR Conundrum</h2>
            <div class="bar"></div>
            <p>i2 is offering a inexpensive, risk free and practical solution using our fixed-fee solicitation model:
                <br>
                Get your fixed fee Solicitation Agent for the following services.
                </p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <ngx-slick-carousel
                        class="carousel feedback"
                        #slickModal="slick-carousel"
                        [config]="imagesSlider">
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/1.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 1</span>
                                <p>Bespoke event set-up with the International
                                    Central Securities Depositories (ICSDs).</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/2.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 2</span>
                                <p>Draft the legal documentation relating to the amended
                                    rate and convening of investor meetings (where appropriate).</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/3.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 3</span>
                                <p>Publishing of meeting and solicitation notices and
                                    materials to the ICSDs.</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/4.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 4</span>
                                <p>Tabulation of investor instructions (received by SWIFT).</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/5.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 5</span>
                                <p>Provide working group with updated and final voting report.</p>
                            </div>
                        </div>                     

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/6.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 6</span>
                                <p>Hosting of, and attendance at, investor meetings (including
                                    voting as proxy).</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/7.jpg" alt="image">
                                </div>

                                <h3>LIBOR Investor Solicitation Service</h3>
                                <span>Step 7</span>
                                <p>Proxy voting on behalf of instructing investors.
                                    </p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>

            <div class="client-thumbnails">
                <div>
                    <ngx-slick-carousel
                        class="carousel thumbs"
                        #slickModal="slick-carousel"
                        [config]="thumbnailsSlider">
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/1.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/2.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/3.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/4.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/5.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/6.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/7.jpg" alt="client"></div>
                        </div>
                    </ngx-slick-carousel>
                </div>

                <button class="prev-arrow slick-arrow">
                    <i class="fas fa-arrow-left"></i>
                </button>

                <button class="next-arrow slick-arrow">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->
