<!-- Start Page Title Area -->


<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70" style="margin-top: 100px;">
    <div class="container">
       <div style="text-align: center;">
           <h3>Welcome To i2 CAPITAL MARKETS</h3>
           <span *ngIf="this.statusShow==true">Your Account has been successfully activated. Log in to your account below.</span>  

           <div *ngIf="this.statusShow==true">
           <br>
            <a href="/signin" class="btn btn-primary">Login Now</a>
           
           </div>
          
       </div>

    </div>
</section>