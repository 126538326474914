<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>About us</h2>           
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About the Founders</span>
                    <h2>Sanjay Jobanputra & Mark Brescacin</h2>
                    <p>
                        Over the past 15 years we have gained substantial experience across a wide range of distressed asset classes and have been particularly active in cross-border enforcement and restructuring scenarios.


                    </p>
                    <p>
                        We work with both issuers and investors to facilitate communication and maximise operational efficiencies to assist a proactive resolution. At i2, we undertake various agency functions including Corporate Trustee, Security Agent as well as assisting with Liability Management exercises.  We also replace incumbent institutions where the parties have reached a ‘stalemate’ situation. Our commitment is to use our substantial experience to help cut-across potential problematic issues whilst working diligently without delay and undue expense.


                    </p>
                   <!---- <p>Sanjay and Mark have acquired over 30 years of combined experience in enforcement and
                        restructuring transactions across the international debt capital markets.</p>
                    <p> They work with both issuers and investors to maximise operational efficiencies and take
                        proactive action including cross-border action. They can perform various agency functions to
                        ensure the distressed asset is worked-out without delay and undue expense thus releasing
                        blockages caused by incumbent institutions.
                    </p>-->

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <!-- <img src="assets/img/about-img1.jpg" alt="image"> -->
                    <img src="assets/img/about_2022.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->
<section class="comparisons-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Our Experience</h2>
            <div class="bar"></div>
            <p>Below are examples of our experiences and transactions that we have administered.</p>
        </div>
        <div class="comparisons-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Deal Name</th>
                        <th scope="col" style="padding-left: 10px;padding-right: 0px;">Description</th>                        
                    </tr>
                </thead>
            </table>
        </div>
        <div class="comparisons-table table-responsive" style="height: 500px;overflow-y: scroll;">
            <table class="table">
               
                <tbody>                    
                    <tr>
                        <td>Alba 2005-1, 2006-2 and 2007-1 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Clavis 2006-1 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    
                    <tr>
                        <td>Clavis 2007-1 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    
                    <tr>
                        <td>CMF 2018-1 </td>
                        <td> Tabulation Agent</td>
                    </tr>
                    
                    
                    <tr>
                        <td>Dragon Finance </td>
                        <td>Tabulation Agent</td>
                    </tr>
                    
                   
                    <tr>
                        <td>Eurohome 2007-1 & 2007-2 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>EuroMASTR 2007-1V </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Eurosail Portfolio </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Eurosail-UK 2007-3BL PLC (UK) </td>
                        <td> Creditor dispute. English court directions</td>
                    </tr>
                    
                   
                    <tr>
                        <td>FAB UK 2004-1 </td>
                        <td> Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Farringdon Mortgages No.2 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>GBP Real Estate Financing </td>
                        <td> Security Trustee</td>
                    </tr>
                    <tr>
                        <td>Global Ports Holding </td>
                        <td> Bondholder I.D., UK Scheme of Arrangement & Tender</td>
                    </tr>
                    <tr>
                        <td>Globe Pub (UK) </td>
                        <td> English receivership and enforcement</td>
                    </tr>
                    <tr>
                        <td>Great Hall Mortgages 2006-1, 2007-1 and 2007-2 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                   
                   
                    <tr>
                        <td>Gulf Keystone Petroleum (UK/ Bermuda)</td>
                        <td>English Scheme of Arrangement</td>
                    </tr>
                   
                    <tr>
                        <td>Harben Finance 2017-1 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Harvest CLO V (Ireland) </td>
                        <td> Bond I.D</td>
                    </tr>
                   
                   
                    <tr>
                        <td>Kirkby RMBS </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Landmark 2 and 3 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Longstone Finance plc </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    
                   
                   
                    <tr>
                        <td>Mansard 2006-1, 2007-1 and 2007-2 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                   
                   
                    <tr>
                        <td>Mortgage Funding 2008-1 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    

                    <tr>
                        <td>PMF 2018-1B & 2B </td>
                        <td> Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Preferred Residential Securities 2005-2 & 2006-1 </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                   
                    <tr>
                        <td>Ripon Mortgages </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>
                    <tr>
                        <td>Uropa Securities </td>
                        <td> I.D., Info & Tabulation Agent</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</section>
<!-- End Services Area -->

<section class="invoicing-area ptb-70">
    <app-funfact></app-funfact>
</section>
<app-talk-button></app-talk-button>
<app-partner></app-partner>