import { Component, OnInit,AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import{ GlobalConstants } from '../../common/GlobalConstants';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var $:any;

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private http: HttpClient, private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder) {}
  
  resetPasswordData: FormGroup;
  submittedResetPassword=false;
  msg:any;
  ngOnInit(): void {
    this.submittedResetPassword=false;
    this.resetPasswordData = this.formBuilder.group({
      password: ['',[Validators.required,Validators.minLength(8)]]


    });
  }

  get f(){
    return this.resetPasswordData.controls;
  }
    
ForgotPasswordSubmit(){

  this.submittedResetPassword=true;
  if(this.resetPasswordData.invalid)
  {
      return;
  }
  else
  {
   
    
    const formdata = new FormData();
    formdata.append('password', this.resetPasswordData.get('password').value);
    formdata.append('time',  this.route.snapshot.params['time']);
    formdata.append('id',  this.route.snapshot.params['id']);

   
   this.http.post<any>(GlobalConstants.apiURL+'forgotPassword.php', formdata).subscribe(response => {
    if(response.status==true)
    {
      this.router.navigate(['/signin']);
    }
   else
   {
    this.msg=response.message;
   }
    });

  }    
}



}
