<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Investor Portal</h2>
            <!--- <p>Event participation and management</p>--->
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-70">
    <div class="row">
        <div class="col-sm-12 text-center">
            <ng-container  *ngIf=" !loggin "><button type="button" (click)="LoginNow()" id="login" class="btn btn-primary">Login to access</button></ng-container>
        </div>
    </div>
    <ng-container  *ngIf="loggin">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="section-title">
                    <h2>Our Transactions</h2>
                    <div class="bar"></div>


                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">

                <div class="">
                    <form>
                        <div class="input-group form-group">
                            <input type="text" name="name" [(ngModel)]="name" id="name" required
                                class="search-box col-md-8" placeholder="Search transaction" />
                            <input class="close-icon" type="reset" value="X" (click)="clearEvent()">
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-primary" (click)="searchEvent()"><i
                                        class="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>

                        </div>

                    </form>

                </div>
                <!---- <form> 
                        <div class="row">
                            <div class="form-group col-md-10">
                                <input type="" name="name" [(ngModel)]="name" id="name" class="form-control">
                            
                            </div>  
                            <div class="col-md-2">
                                <button type="submit" class="btn btn-primary" (click)="searchEvent()"><i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div> 
                        </div>
                       
                    </form>-->


            </div>
        </div>


        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let event of eventListData">
                <div class="single-blog-post">
                    <div class="blog-image" style="min-height: 100px;text-align:center;vertical-align: middle;">
                        <a (click)="goToEventDetailsPage(event.id,event.eventType,event.nameUrl)">
                            <img style="max-height: 100px;" [src]="filePath+'/event_logo/'+event.id+'/'+event.logo">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> {{event.start_date}}
                        </div>
                    </div>

                    <div class="blog-post-content section-title" style="min-height: 280px;">
                        <h3><a (click)="goToEventDetailsPage(event.id,event.eventType,event.nameUrl)"
                                style="cursor: pointer;"> {{event.name}}</a></h3>
                        <div class="bar"></div>
                        <p [innerHtml]="event.public_description.substring(0, 100)"></p>
                        <!--<span (click)="goToEventDetailsPage(event.id,event.eventType,event.nameUrl)" class="read-more-btn" style="cursor: pointer;border-radius: 50px;border: 1px solid #243A75 ;padding: 8px 10px 8px 10px;">Access <i class="fas fa-arrow-right"></i></span>-->
                        <!---- <span (click)="goToEventDetailsPage(event.id,event.eventType,event.nameUrl)" class="read-more-btn btn btn-primary" style="cursor: pointer;color: white;">Access <i class="fas fa-arrow-right"></i></span>-->
                        <span (click)="goToEventDetailsPage(event.id,event.eventType,event.nameUrl)"
                            class="read-more-btn" style="cursor: pointer;"><b>Access <i
                                    class="fas fa-arrow-right"></i></b></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12" *ngIf="this.count && this.count >= 1">
                <div class="pagination-area">
                    <a class="prev page-numbers" *ngIf="this.previous && this.previous >= 1"
                        (click)="goToEvent(this.previous)"><i class="fas fa-angle-double-left"></i></a>


                    <!--- <a class="page-numbers" *ngFor="let item of arrayOne(this.count); let i = index;" (click)="goToEvent(i+1)" >{{i+1}}</a>-->
                    <span class="page-numbers current" aria-current="page">{{this.currentPage}}</span>

                    <a class="next page-numbers" *ngIf="this.next && this.next <= this.count"
                        (click)="goToEvent(this.next)"><i class="fas fa-angle-double-right"></i>

                    </a>
                </div>
            </div>

        </div>
        <!---->
        <hr>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="faq-content">
                    <h2>Frequently Asked Questions</h2>
                    <div class="bar"></div>
                    <p>Your most common questions answered here.</p>
                    <p>If you require any further information, please get in touch at info@i2capmark.com.</p>

                    <div class="faq-image">
                        <img src="assets/img/faq.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion_1">


                    <ul class="accordion_1">
                        <!--<li class="accordion-item_1">
                            <a class="accordion-title_1 active" href="javascript:void(0)" id="1" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="1"></i>
                                	How to Get Started 
                            </a>

                            <p class="accordion-content_1 show" id="1_p">
                                Click the Transactions page to view all deals. Select the transaction you would like to access. If you are not a registered user on our site, see ‘User Registration’ below. If you have already registered successfully, see ‘Transaction Participation’ below.
                            </p>
                        </li>

                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="2" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="2"></i>
                                	User Registration
                            </a>

                            <p class="accordion-content_1" id="2_p">
                               
                                If you have not registered with us, please enter the required information in the sign-up form in the ‘Log In’ page. For individuals, you may leave the Organisation Name field blank. Please review our Privacy Policy and T&C’s and if you agree, please approve by ticking the relevant box before you submit your details.
                                <br><br>
                                Check your email for an account verification message and change your password using the provided link (check your junk folder and approve us a permitted sender). Your registration for a user account is now complete and you will not be required to do this stage again.   
                            </p>
                        </li>-->

                        <li class="accordion-item_1">
                            <a class="accordion-title_1 active" href="javascript:void(0)" id="1"
                                (click)="showDiv($event)">
                                <i class="fas fa-plus" id="1"></i>
                                How to Get Started
                            </a>

                            <p class="accordion-content_1 show" id="1_p">
                                Click the <u>Transactions</u> page to navigate to our Investor Portal and view all
                                events. Select the event you would like to access. If you are not a registered user, see
                                ‘User Registration’. If you have already registered, see ‘Transaction Participation’.
                            </p>
                        </li>

                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="2" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="2"></i>
                                User Registration
                            </a>

                            <p class="accordion-content_1" id="2_p">

                                Enter the required information in the <u>sign-up</u> form in the ‘Log In’ page. For
                                individuals, leave the Organisation Name field blank. Review our Privacy Policy and
                                Terms and Condition’s and, if you agree, approve by ticking the relevant box before your
                                submit account registration.
                                <br><br>

                                You will receive an automated email requesting you <u>Confirm Account</u>. Once
                                confirmed, change your password using the provided link (check your junk or spam folder
                                and approve us as a permitted sender). Your registration is now complete.
                            </p>
                        </li>
                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="3" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="3"></i>
                                Transaction Participation
                            </a>


                            <p class="accordion-content_1" id="3_p">
                                Investors wishing to participate or access information for a particular transaction
                                (offer documents, press releases, notices etc.), select <u>Access</u> for that
                                transaction and <u>Request Participation</u>. Submit the <u>Participation Form</u>
                                following the guidance provided.
                                <br><br>
                                You will receive an automated email once your account request has been processed. Once
                                approved, you may access the transaction.
                            </p>
                        </li>
                        <!----
                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="4" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="4"></i>
                                	Holding Information
                            </a>

                           
                            <p class="accordion-content_1" id="4_p">
                             
                            Check the status of your transaction participation using the ‘My Holdings’ tab. The ‘Event Participation’ column shows your status; Pending for when your submission is awaiting approval, Rejected for when your submission is incorrect, and Accepted for when your submission has been verified. In case of a Rejection status, you will receive an email explaining the reason (you can also view this when you hover your mouse over the Rejection status in the Action column). In case of issues, please either re-submit the required information using Edit in the Action column or contact us at info@i2capmark.com for further help.
                            </p>
                        </li>

                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="5" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="5"></i>
                            	Action Information (Uploads / Trades)
                            </a>
                            
                            <p class="accordion-content_1" id="5_p">
                                In case you are required to submit further documentation, log into your user account and access the ‘My Holdings’ tab for a particular event. In the Action column, click ‘Uploads’ to submit any required documentation and view the status in the provided submission log. 
                           
                            <br><br>
                                Sometimes, you will be required to provide the name of your counter-party (if they are registered on the site and they agree for you to do so) and the position will automatically be updated accordingly. 
                            </p>
                        </li>
                    -->

                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="6" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="6"></i>
                                Working Group
                            </a>


                            <p class="accordion-content_1" id="6_p">
                                For use only by Issuers, Borrowers, Investor Committee(s) and their legal and financial
                                advisors. Contact us at info@i2capmark.com if you require access to a particular
                                transaction.
                            </p>
                        </li>

                        <li class="accordion-item_1">
                            <a class="accordion-title_1" href="javascript:void(0)" id="7" (click)="showDiv($event)">
                                <i class="fas fa-plus" id="7"></i>
                                Why Register?
                            </a>


                            <p class="accordion-content_1" id="7_p">
                                Delays are customarily experienced when notices and documents are distributed through
                                the clearing systems and their intermediaries. Our Investor Portal will provide you with
                                immediate access to all offer documentation, notices and announcements. Contact us at
                                info@i2capmark.com for further assistance.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>





    </div>
    </ng-container>
</section>
<!-- End Blog Area -->
<section class="blog-area ptb-70 ">
    <app-talk-button></app-talk-button>
    <app-partner></app-partner>
</section>