<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <div class="article-image">
                <img [src]="filePath+'event_logo/'+ eventDetails.id +'/'+eventDetails.logo" width="250px" alt="image">
            </div>
            <h2 *ngIf="eventDetails">{{eventDetails.name}}</h2>
            <!--<p>Our latest Event</p>-->
            
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70" style="margin-top: -70px;">
    <div class="container">
        <div class="section-title" style="margin-top: 10px;">
            <h2>Terms and Conditions
            </h2>
            <div class="bar"></div>            
        </div>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <!-----start event------->
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        
                        <div class="blog-details">
                            <div class="article-content" style="margin-top: -15px;;">
                              
                                <p [innerHTML]="eventDetails.termsCondition"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!---------end--->
            </div>

        </div>
    </div>
</section>