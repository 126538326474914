<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <div class="article-image">
                <img [src]="filePath+'event_logo/'+ eventDetails.eventId +'/'+eventDetails.logo" width="250px" alt="image">
            </div>
            <h2 *ngIf="eventDetails">{{eventDetails.eventName}}</h2>
            <!--<p>Our latest Event</p>-->
            
        </div>
    </div>
</div>
<!-- End Page Title Area -->
<section class="blog-details-area ptb-70">
    <div class="container">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                    aria-selected="true">
                    <h6>Event Details</h6>
                </a>
            </li>
           
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <!-----start event------->
                <div class="row" style="margin-bottom: -50px;">
                    <div class="col-lg-12 col-md-12">
                        <div class="blog-details">
                            <div class="article-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><span>Start On:&nbsp;</span> <a href="#"
                                                *ngIf="eventDetails">{{eventDetails.startDate}}</a></li>
                                        <li><span>Last Date: &nbsp; </span>   <a href="#"
                                                *ngIf="eventDetails">{{eventDetails.endDate}}</a></li>
                                    </ul>
                                </div>
                               
                              
                                <p  [innerHTML]="eventDetails.publicDescription"></p>
                               

                                <div *ngIf="eventDetails">
                                    <p *ngIf="eventDetails" [innerHTML]="eventDetails.privateDescription"></p>
                                    <div class="row">
                                        <svg class="bi bi-file-earmark-check" width="1.5em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v2.5A1.5 1.5 0 0 0 10.5 6H13v2h1V6L9 1z"/>
                                            <path fill-rule="evenodd" d="M15.854 10.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708l1.146 1.147 2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                        </svg> &nbsp;&nbsp;
                                        <a href="" data-toggle="modal" data-target="#tandc" style="z-index: 5;"><h4>Terms & Conditions</h4></a>
                                    </div>
                                </div>
                                    <!-- Modal T&C-->
                                 <div class="modal fade" id="tandc" role="dialog">
                                    <div class="modal-dialog">
                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">Terms & Conditions</h4>
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>
                                            <div class="modal-body" [innerHTML]="eventDetails.termsCondition">                                               
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <!--modal end-->
                            </div>
                        </div>
                    </div>
                </div>
                <br><br><br>
                <div *ngIf="eventDetails ">
                    <div class="row" *ngFor="let documentSection of documentDetails"> 
                        <div class="col-lg-12 col-md-12">
                            <section>
                                <div class="container-fluid">
                                    <div class="overview-box">
                                        <div class="overview-content">
                                            <div class="content left-content">
                                                <h2 class="widget-title">{{documentSection.documentSection}}</h2>
                                                <div class="bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div  class="container">
                                        <div class="featured-boxes-inner">
                                            <div class="row m-0">

                                                <div class="col-lg-3 col-sm-6 col-md-6 p-0"
                                                    *ngFor="let sectionDetails of documentSection.documentSectionDetails">
                                                    <div class="single-featured-box">
                                                        <div [ngClass]="documentSection.class">
                                                            <i class="flaticon-envelope-of-white-paper"></i>
                                                        </div>
                                                        <h3>{{sectionDetails.documentTitle}}</h3>
                                                        <p>{{sectionDetails.documentDescription}}
                                                        </p>
                                                       <!----<a style="cursor: pointer;" (click)="DownloadFile(sectionDetails.documentUpload)" class="read-more-btn">Download</a>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!---------end--->
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <br>
                <!---start Holding--->
                <!--end holding--->
            </div>
        </div>
    </div>
</section>
