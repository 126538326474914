<!-- Start Signup Area -->
<section class="signup-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-image">
                <img src="assets/img/signup-bg.jpg" alt="image">
            </div>
        </div>

       


        <div class="col-lg-6 col-md-12 p-0">
            <button [hidden]="true" #myModal data-toggle="modal" data-target="#myModal">open modal</button>
                <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">Signup</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <div >
                                <span *ngIf="this.showError==true" style="color:red;">{{msg}}</span>  
                                <span *ngIf="this.showError==false" style="color:green;">Please check your email for account activation</span>  

                            </div>
                        </div>
                        <div  class="modal-footer">
                            <button *ngIf="this.showError==false" (click)="goToPage();" type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
                            <button *ngIf="this.showError==true" type="button" class="btn btn-secondary" data-dismiss="modal" >Ok</button>
                       
                        </div>
                    </div>
                    </div>
                </div>

            <div class="signup-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="signup-form">
                            <div class="logo">
                                <a href="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>

                            <h3>Create your account</h3>
                            <p>Already signed up? <a href="/signin"> <b><u>Log in</u></b></a></p>
                              
                            <form [formGroup]="signUpData" (ngSubmit)="onSubmit()">
                                <div class="form-group">


                                    
                                    <input type="text" formControlName="name" name="name" id="name" placeholder="Insert Name " class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.name.errors.required"> Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Insert Email ID" name="email" id="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                   <div *ngIf="submitted && f.email.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.email.errors.required">Email is required</div>                                       
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submitted && f.email.invalid && (f.email.dirty || f.email.touched)" style="color:red;text-align: left;">Please enter valid email</div>                                   
                                </div>
                                
                                <div class="form-group">
                                    <div class="row">
                                                <div class="col-5" style="padding-right: 0;">
                                                    <select name="countryCode" id="countryCode" class="form-control" formControlName="countryCode" [(ngModel)]="countryCode">
                                                       
                                                         <option *ngFor="let cdialcode of countryDetails">
                                                             {{cdialcode.phoneCode}}</option>
                                                     </select>
                                                    

                                                </div>
                                                <div class="col-7" style="padding-left: 0;">
                                                    <input class="form-control" type="text" formControlName="mobile" name="mobile" id="mobile" placeholder="Insert Mobile No" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                                    <!--<small id="" class="form-text text-muted" style="text-align: left;"><b>Enter Mobile no. with Country Code e.g +44 7718340415</b></small>-->
                                                    <!--<input class="form-control" style="width: 35em;" type="text" ng2TelInput [ng2TelInputOptions]="{phoneValidation:true,separateDialCode: true,hiddenInput:'full_phone'}" (countryChange)="onCountryChange($event)" formControlName="mobile" name="mobile" id="mobile" placeholder="Insert Mobile No" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />-->
                                                    <!--<input type="text" formControlName="mobile" name="mobile" id="mobile" placeholder="Insert Mobile No" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />-->
                                                   
                                                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                                        <div *ngIf="f.mobile.errors.required"> Mobile No  is required</div>
                                                       
                                                    </div>
                                                    <div class="invalid-feedback" *ngIf="submitted && f.mobile.invalid && (f.mobile.dirty || f.mobile.touched)" style="color:red;text-align: left;">Please enter number only</div>                                  
                                                   

                                                </div>
                                    </div>
                                   
                                   
                                </div>    
                                <div class="form-group">
                                    <input type="text" formControlName="organization" name="organization" id="organization" placeholder="Insert organization name, else leave blank" class="form-control" />
                                    
                                 </div>
                                <!----
                                 <div class="form-group">
                                        <input type="text" formControlName="organization" name="organization" id="organization" placeholder="Insert organization name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.organization.errors }" />
                                    <div *ngIf="submitted && f.organization.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.organization.errors.required"> organization name is required</div>
                                    </div>
                                </div> -->                      
                                <div class="form-group">
                                    <input type="password" id="password" formControlName="password" placeholder="Create a Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters</div>
                                    </div>
                                </div>
                               
                               
                                
                                <div class="form-group" style="text-align:left;">
                                    <label><input type="checkbox" formControlName="cb" id="cb"> <a href="policy" target="_blank"> 
                                        I agree to the Terms of Use and Privacy Policy</a></label>
                                    <div style="color: red;" *ngIf="submitted && signUpData.hasError('required', 'cb')">
                                       
                                        <div style="margin-top:0.25rem;font-size:80%;color:red">  Please accept terms & conditions</div>

                                    </div>
                                   <!----<label><input type="checkbox"
                                        [checked]="TC"
                                        (change)="TC = !TC" name="TC" id="TC"                                     
                                        [ngClass]="{ 'is-invalid': submitted && !TC }">                                        
                                        <a href="policy" target="_blank"> 
                                            I agree to the Terms of Use and Privacy Policy</a></label>
                                        <div *ngIf="submitted && !TC" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545">
                                            <div style="color:red">Please accept terms and conditions</div>
                                        </div>  -->                                      
                                </div>
                                <input type="hidden" id="code_1" name="code_1" value="+1" class="form-control">
                                <button type="submit" class="btn btn-primary">Submit</button>
            
                            </form>
                               <!--- <form>
                                    <div class="form-group">
                                        <input type="text" name="username" id="username" [(ngModel)]="username"
                                            placeholder="Insert name" class="form-control" #userNameControl="ngModel" required >

                                    </div>

                                    <div class="form-group">
                                        <input type="email" name="email" id="email" [(ngModel)]="email"
                                            placeholder="Insert email id" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <input type="number" name="mobile" id="mobile"
                                            [(ngModel)]="mobile" placeholder="Insert mobile number"
                                            class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" id="password"
                                            [(ngModel)]="password" placeholder="Create a password"
                                            class="form-control">
                                    </div>
                                    <button type="submit" (click)="postRegisterData()" class="btn btn-primary">Sign Up</button>

                                </form>-->
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->
