<!-- Start Fun Facts Area -->
<section class="funfacts-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Transaction Experience</h2>
            <div class="bar"></div>
            <p>We are an expert driven business with over 30 years of combined experience in distressed
                work-out situations across all debt capital markets products.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-4 col-md-4 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="150">00</span></h3>
                    <p>Deal Experience</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-4 col-md-4 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="40">00</span></h3>
                    <p>Countries</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-4 col-md-4 col-6">
                <div class="funfact">
                    <h3>$<span class="odometer" data-count="50">00</span>bn</h3>
                    <p>Value</p>
                </div>
            </div>

           
        </div>

        <div class="contact-cta-box">
            <h3>Have any questions for us?</h3>
            <p>Don’t hesitate to get in touch</p>
            <a href="/contact" class="btn btn-primary">Contact Us</a>
        </div>

        <div class="map-bg">
            <img src="assets/img/map.png" alt="map">
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->
