import { Component, OnInit,AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import{ GlobalConstants } from '../../common/GlobalConstants';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as forge from 'node-forge';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('myModal', {static : true}) myModal:ElementRef;

  constructor(private http: HttpClient, private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder) {}
  contactFormData: FormGroup;

submitted=false;
showData:any=true;
submittedStatus=false; 

ngOnInit() {
    this.showData=true;
    this. submittedStatus=false;   
    this.contactFormData = this.formBuilder.group({
      email:['',[Validators.required, this.emailValidator]],
      name:['',[Validators.required]],
      msg:['',[Validators.required]]
    
    });
  }

  get f(){
    return this.contactFormData.controls;
  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  
  onSubmit() {
    var rs=forge.pki.publicKeyFromPem(GlobalConstants.publicKey); 
      this.submitted=true;
      if(this.contactFormData.invalid)
      {
        this.submittedStatus=false; 
          return;
      }
      else
      {
        
        const formdata = new FormData();
        formdata.append('email', window.btoa(rs.encrypt(this.contactFormData.get('email').value)));
        formdata.append('name', window.btoa(rs.encrypt(this.contactFormData.get('name').value)));
        formdata.append('msg', this.contactFormData.get('msg').value);
        this.http.post<any>(GlobalConstants.apiURL+'contact_form.php', formdata).subscribe(response => {
        if(response.status==true){
          this. submittedStatus=true;   
          $("#name").val("");
          $("#email").val("");
          $("#msg").val("");
          this.myModal.nativeElement.click();

        }
        else{
          this. submittedStatus=false;   
          this.myModal.nativeElement.click();

        }
          
       
      
        });

      }
  }


}
