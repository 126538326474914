<!-- Start Page Title Area -->
<div class="page-title-area item-bg1" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>How can we help?</h2>            
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Contact us with your query</h2>
            <div class="bar"></div>
            <p>Bonds | Loans | High Yield | Structured Products | Trustee | Security Agent | Restructuring | Enforcement | Dispute Resolution</p>
        </div>
        <div class="row">
           <button [hidden]="true" #myModal data-toggle="modal" data-target="#myModal">open modalss</button>
       </div>         
      
        <div class="row" >
            <div class="col-lg-5 col-md-12">
                 <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>Address</span>
                            <!-- Kemp House, 160 City Rd, London, EC1V 2NX, United Kingdom -->
                            Office: 128 City Rd, London, EC1V 2NX, United Kingdom


                        </li>

                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>Email</span>
                            <a href="#">info@i2capmark.com</a>                            
                        </li>

                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Phone</span>
                            <a href="#">+44 (0) 20 3633 1212</a>
                            <!--<a href="#">+44 7708 902 436</a>
                            <a href="#">+44 7772 710 882</a>-->
                        </li>
                    </ul>
                </div>
            </div>
             
            <div class="col-lg-7 col-md-12">
               
                <div class="contact-form">
                    <!--<form id="contactForm" method="POST" action="contact">-->
                        <form *ngIf="showData" [formGroup]="contactFormData" (ngSubmit)="onSubmit()">

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">


                                    <input type="text" formControlName="name" name="name" id="name" placeholder="Name " class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.name.errors.required"> Name is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" placeholder=" Email" name="email" id="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                   <div *ngIf="submitted && f.email.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.email.errors.required">Email is required</div>                                       
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submitted && f.email.invalid && (f.email.dirty || f.email.touched)" style="color:red;text-align: left;">Please enter valid email</div>                                   
                                </div>
                            </div>                          

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                   
                                    <textarea name="msg" class="form-control" formControlName="msg" id="msg" cols="30" rows="10" required data-error="Write your message" placeholder="Your Message (Please complete in English)" [ngClass]="{ 'is-invalid': submitted && f.msg.errors }" ></textarea>
                                    <div *ngIf="submitted && f.msg.errors" class="invalid-feedback" style="color:red;text-align: left;">
                                        <div *ngIf="f.msg.errors.required"> Message is required</div>
                                    </div>        



                                    <small id="emailHelp" class="form-text text-muted">By submitting your details, you consent to their use as set out in our <a href="policy" target="_blank">Privacy Policy</a></small>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
    <div class="modal fade" data-backdrop="false" id="myModal" style="padding-top: 50px;" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">Contact Us</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div >
                    <span *ngIf="this.submittedStatus==false" style="color:red;">Something went wrong</span>  
                    <span *ngIf="this.submittedStatus==true" style="color:green;">Contact details have been successfully submitted.</span>  

                </div>
            </div>
            <div  class="modal-footer">
              
                <button type="button" class="btn btn-secondary" data-dismiss="modal" >Ok</button>
           
            </div>
        </div>
        </div>
    </div>


</section>

<app-account-button></app-account-button>
