import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams  } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import{ GlobalConstants } from '../../common/GlobalConstants';
//import { AutoLogoutService } from '../../common/autologout.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'ApiAuth xyz'
  })
}

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
  eventListData: any;
  eventDetails:any;
  pastEventListData: any;
  filePath: any;
  count:any;
  previous:any;
  next:any;
  currentPage:any;
  name:any;
  ct:any;
  loggin = false;

 // test='Angular';
 constructor(private http: HttpClient, private router: Router,private activatedRoute: ActivatedRoute) { 
 // this.test = service.val;

 }

  ngOnInit(): void {
    
    this.ct=1;
    this.name="";
    this.filePath=GlobalConstants.apiURL+'uploads/'
    this.getEventList(this.activatedRoute.snapshot.params['page'],this.name);
    this.getPastEventList();
    this.currentPage=parseInt(this.activatedRoute.snapshot.params['page']);
    this.previous=parseInt(this.activatedRoute.snapshot.params['page'])-1;
    this.next=parseInt(this.activatedRoute.snapshot.params['page'])+1;
    this.loggin = sessionStorage.getItem('userId') ? true : false;
    
    jQuery(function( $ ){

       $(".accordion_1 li").click(function(){
//alert(this);
      $(this).find('p').slideToggle();
    //  $(this).find('a').addClass('active');
    $(this).find('a').toggleClass("active");

      $(".accordion_1 li").not(this).find('p').slideUp();
      $(".accordion_1 li").not(this).find('a').removeClass('active');
     // $(this).find('a').addClass('active');
    //$(".accordion_1 li").not(this).children('p').removeClass('active');
    
  });
      /*$('.accordion_1 .accordion-title_1').click( function(){
      $(this).parent().find('.accordion-content_1').slideToggle('fast');
      $(this).toggleClass('on');
      return false;
    });*/
  
  });
  
    
  }

  getEventList(page:any,name:any):any {
  	this.http.get(GlobalConstants.apiURL+'eventList.php?page='+page+'&name='+name, httpOptions).subscribe(response => {      
      this.eventDetails=response;
      this.eventListData = this.eventDetails.data;
      this.count=this.eventDetails.total_page;
     // this.previous=parseInt(page)-1;  
      //this.next=parseInt(page)+1;
      console.log(response);
    })
  }
  arrayOne(n: number): any[] {
    return Array(n);
  }
  getPastEventList():any {
  	this.http.get(GlobalConstants.apiURL+'pastEventList.php', httpOptions).subscribe(response => {
      this.pastEventListData=response;
  	})
  }
  LoginNow() {
    this.router.navigate(['/signin']);
  }
  goToEventDetailsPage(id:any,type:any,name:any):any{
   // console.log(id);
    this.router.navigate(['/event-details',id,type,name]);

    }
    goToEvent(page:any):any{
       console.log(page);
       this.router.navigate(['/transactions',page]);
       this.getEventList(page,this.name);
       this.previous=parseInt(page)-1;  
       this.next=parseInt(page)+1;
       this.currentPage=parseInt(page);
      //alert(this.next);
    //  this.next=page; 
     }
     searchEvent()
     {
    
     // alert(this.name);
     this.router.navigate(['/transactions',1]);
     this.getEventList(1,this.name);
     //alert(this.activatedRoute.snapshot.params['page']);
     this.currentPage=parseInt(this.ct);
     this.previous=parseInt(this.ct)-1;
     this.next=parseInt(this.ct)+1;
     }
     clearEvent()
     {
    
     this.router.navigate(['/transactions',1]);
     this.getEventList(1,'');
     this.currentPage=parseInt(this.ct);
     this.previous=parseInt(this.ct)-1;
     this.next=parseInt(this.ct)+1;

     
     }
     showDiv(event)
     {
  /*
      var a=document.getElementsByClassName('accordion-title_1');
      
      var i;
      for (i = 1; i <= a.length; i++) {
        var y=document.getElementById(i);
        y.classList.remove("active");

        var z=document.getElementById(i+'_p');
        z.classList.remove("show");
        if(event.target.id==i)
        {
         var x=document.getElementById(event.target.id);
          x.classList.add("active");
          var k=document.getElementById(event.target.id+'_p');
          k.classList.add("show");

          


        }
        
      
      }

     //document.getElementById("a_1_p").style.display="none";




*/

     }

}
