import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import{ GlobalConstants } from '../../common/GlobalConstants';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'ApiAuth xyz'
  })
}

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent implements OnInit {

  
  constructor(private http: HttpClient, private router: Router,private activatedRoute: ActivatedRoute) { }
 
statusShow=false;
msg:any;
  ngOnInit(): void {
   this.statusShow=false;
   this.sendData(this.activatedRoute.snapshot.params['user_id']);
   //alert(this.activatedRoute.snapshot.params['user_id']);

  }
  
  sendData(id:any):any {
    const formdata = new FormData();
    formdata.append('id',id);
   
   this.http.post<any>(GlobalConstants.apiURL+'accountActivation.php', formdata).subscribe(response => {
    this.statusShow=true;

  })
  	/*this.http.get(GlobalConstants.apiURL+'accountActivation.php?id='+id, httpOptions).subscribe(response => {      
      this.statusShow=true;
     
    })*/
  }
  
  
}
