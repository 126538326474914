export class GlobalConstants {    
 //public static apiURL: string = "http://uat.i2capmark.com/event/i2_api/"; 
 // public static apiURL: string = "http://i2.appzy9.com/event/i2_api/"; 
//public static apiURL: string = "http://localhost/event_i2/i2_api/";  
public static apiURL: string = "https://i2capmark.com/event/i2_api/"; 

public static publicKey:string=`-----BEGIN PUBLIC KEY-----
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArmUq87zeO9DCUodzQAeK
  OViuvp8GuyVVnE5tYC58NDWxYtFKBVEB+e6su2NbmQyVlqlJ2l1CAPCssAfAegzm
  CIIKLlhkFbOuH7Jt2l8DQ3FQgE0OQxhi1oAk9tnmIBSmvyjRCJ9XqCaIE3gOXmTc
  g7F2sd1k6uX957jJKzW0AStyQFUv8yV0HipwG5VY9VLpnCjb5QaGX7Nk7NwEHUQc
  Ye7YFIktj0DwB/cI2s+MOUgy7fRJ8cgxaJ1i1saJDpbjYIMYUW3s9JT/bUN3RZco
  Jjy9iCgExpUCpwViiSdCmPg7Qbe4TqRLZbY36LCSJQfQRl3pjmC02uhJjJnp2I6E
  HOj/PthVIcnfFDoezSm8zSZ/gYl1Dx2y9kwBTDbGgerYP0OD+7AXOR5IzCT0rlkI
  GjqV+HOHMp2uiEvKEgRHxPo017jygr8BERFPc0B31JTLcpzXAsG78694C3+74Ol4
  a2zD6JJP/ZK0KCQUW8N1cB6+YdNp4Ow83qr5lya27R/c+xAvBcfMA2//Fdm5+zd8
  Gt1V1+BoxSgpKJAUzsUpcsH+y8JYN/hayu2PIZFbuWPBb2KqkyrTNWVBgJF2cXlG
  QNcy4mytNZXXPru9GV5zBSZ8RNsi53zes90c7hlH2qxscjHmio2sQP3NjS52Tv3e
  WAUWryE2pRK4JA0jm5qqJzsCAwEAAQ==
  -----END PUBLIC KEY-----
  `; 
   
}