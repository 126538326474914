import { Component, OnInit,AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import{ GlobalConstants } from '../../common/GlobalConstants';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as forge from 'node-forge';
declare var $:any;


//import { format } from 'path';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {


  loginPassword:any;
  loginemail:any;
  msg:any;
  showLogin:any=true;
  showError=false;
  
  @ViewChild('myModal', {static : true}) myModal:ElementRef;
  constructor(private http: HttpClient, private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder) {}
  /*added*/
  resetPasswordData: FormGroup;
  signInData: FormGroup;
  submittedLogin=false;
  submittedResetPassword=false;
  ngOnInit() {
   

    this.showLogin=true;
    this.showError=false;
    this.signInData = this.formBuilder.group({
      email:['',[Validators.required, this.emailValidator]],
      password: ['',[Validators.required,Validators.minLength(8)]]
    
    });
    this.resetPasswordData = this.formBuilder.group({
      email_1:['',[Validators.required, this.emailValidator]]
     
    
    });
}


get f(){
  return this.signInData.controls;
}

get resetForm(){
  return this.resetPasswordData.controls;
}
emailValidator(control) {
  if (control.value) {
    const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
    return matches ? null : { 'invalidEmail': true };
  } else {
    return null;
  }
}

  ForgotPassword(){
    this.showLogin=false;
  }

  ForgotPasswordSubmit(){
    var rs=forge.pki.publicKeyFromPem(GlobalConstants.publicKey); 
    this.submittedResetPassword=true;
    if(this.resetPasswordData.invalid)
    {
        return;
    }
    else
    {
      
      const formdata = new FormData();
      formdata.append('email', window.btoa(rs.encrypt(this.resetPasswordData.get('email_1').value)));
     
     this.http.post<any>(GlobalConstants.apiURL+'forgotPasswordLink.php', formdata).subscribe(response => {
     if(response.status==true)
     {
      this.showError=false;
      this.msg=response.message;
     }
     else
     {
      this.showError=true;
      this.msg=response.message;
     }
    
      this.myModal.nativeElement.click();
    
      });

    }    
  }

  Login(){
    this.showLogin=true;
  }

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";  
  isValidFormSubmitted = false; 
/*
  postLoginData():any {
    
    let jsonData = new HttpParams()
    .set('email', this.loginemail)
    .set('password', this.loginPassword);
   
  	this.http.post(GlobalConstants.apiURL+'login.php',jsonData).subscribe(response => {
      if(response[0] && response[0].status && response[0].status == true) {
        sessionStorage.setItem('name',response[0].name);
        sessionStorage.setItem('userId', response[0].user_id);
        this.router.navigate(['/trasactions']);
      }else {        
     //   this.msg=response.message;
      }    
    });
  }  */

  submitted = false;


  onSubmit() {
    var rsa=forge.pki.publicKeyFromPem(GlobalConstants.publicKey);

      this.submitted=true;
      this.submittedLogin=true;
      if(this.signInData.invalid)
      {
          return;
      }
      else
      {
        const formdata = new FormData();
        //alert(window.btoa(rsa.encrypt(this.signInData.get('password').value)));
       // formdata.append('email', this.signInData.get('email').value);
       // formdata.append('password', this.signInData.get('password').value);
       formdata.append('email',window.btoa(rsa.encrypt(this.signInData.get('email').value)));
       formdata.append('password',window.btoa(rsa.encrypt(this.signInData.get('password').value)));
        this.http.post<any>(GlobalConstants.apiURL+'login.php', formdata).subscribe(response => {
          if(response[0] && response[0].status && response[0].status == true) {
            sessionStorage.setItem('name',response[0].name);
            sessionStorage.setItem('userId', response[0].user_id);
            this.router.navigate(['/transactions/1']);
          }else {       
         
          this.showError=true;
          this.msg=response.message;
          this.myModal.nativeElement.click();
        //   this.msg=response.message;
          }    

        });

      }
  }


}

export class Hero {

  constructor(
    public id: number,
    public name: string,
    public power: string,
    public alterEgo?: string
  ) {  }

}
