<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <div class="article-image">
                <img [src]="filePath+'event_logo/'+ eventDetails.eventId +'/'+eventDetails.logo" width="250px" alt="image">
            </div>
            <h2 *ngIf="eventDetails">{{eventDetails.eventName}}</h2>
            <!--<p>Our latest Event</p>-->
            
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70">
    <div class="container">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                    aria-selected="true">
                    <h6>Event Details</h6>
                </a>
            </li>
            <li class="nav-item" *ngIf="loggin">
                <a class="nav-link" (click)="getHoldingData()" id="profile-tab" data-toggle="tab" href="#profile"
                    role="tab" aria-controls="profile" aria-selected="false">
                    <h6>My Holdings</h6>
                </a>
            </li>
            <li class="nav-item" *ngIf="loggin && eventDetails.entitlement == 1">
                <a class="nav-link" (click)="getHoldingData()" id="entitlement-tab" data-toggle="tab" href="#entitlement"
                    role="tab" aria-controls="profile" aria-selected="false">
                    <h6>My Entitlements</h6>
                </a>
            </li>
        </ul>
        
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <!-----start event------->
                <div class="row" style="margin-bottom: -50px;">
                    <div class="col-lg-12 col-md-12">
                        
                         
                        <div class="blog-details">
                            <div class="article-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><span>Start On:&nbsp;</span> <a href="#"
                                                *ngIf="eventDetails">{{eventDetails.startDate}}</a></li>
                                        <li><span>Last Date: &nbsp; </span>   <a href="#"
                                                *ngIf="eventDetails">{{eventDetails.endDate}}</a></li>
                                    </ul>
                                </div>
                                <p *ngIf="eventDetails && eventDetails.upcoming == false" style="font-size: 24px;color:red">Event has ended.</p>
                                <p *ngIf="eventDetails && eventDetails.start_event == false" style="font-size: 24px;color:red">Event has not started.</p>
                                <p *ngIf="eventDetails && eventDetails.isApproved == 0"  [innerHTML]="eventDetails.publicDescription"></p>
                               

                                <div *ngIf="eventDetails && eventDetails.isApproved != 0">
                                    <p *ngIf="eventDetails" [innerHTML]="eventDetails.privateDescription"></p>
                                    <div class="row">
                                        <svg class="bi bi-file-earmark-check" width="1.5em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v2.5A1.5 1.5 0 0 0 10.5 6H13v2h1V6L9 1z"/>
                                            <path fill-rule="evenodd" d="M15.854 10.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708l1.146 1.147 2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                        </svg> &nbsp;&nbsp;
                                        <a href="" data-toggle="modal" data-target="#tandc" style="z-index: 5;"><h4>Terms & Conditions</h4></a>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <button type="button" *ngIf="loggin && eventDetails.holdingCount==0 && eventDetails.upcoming == true && eventDetails.start_event == true"
                                                class="btn btn-primary" id="enrollNow" data-toggle="modal"
                                                data-target="#myEnroll" (click)="AddHolding()">Request Participation</button>&nbsp;
                                            <button type="button" 
                                                *ngIf="loggin && eventDetails.holdingCount==1 && !eventDetails.isApproved && eventDetails.holdingCount!=0 && eventDetails.upcoming == true && eventDetails.start_event == true"
                                                class="btn btn-primary" (click)="ManageBeneficiary()">Manage Participation</button>&nbsp;
                                            
                                            <button type="button" *ngIf=" !loggin && eventDetails && eventDetails.upcoming == true && eventDetails.start_event == true" id="login" class="btn btn-primary"
                                               (click)="LoginNow()">Login to access</button>
                                               
                                        </div>
                                    </div>
                                </div>
                                 <!-- Modal T&C-->
                                 <div class="modal fade" id="tandc" role="dialog">
                                    <div class="modal-dialog">
                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">Terms & Conditions</h4>
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>
                                            <div class="modal-body" [innerHTML]="eventDetails.termsCondition">                                               
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <!--modal end-->
                                <!-- Modal -->
                                <div class="modal fade" id="myModal" role="dialog">
                                    <div class="modal-dialog">

                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">Register Now</h4>
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>
                                            <div class="modal-body">
                                                <!--form-->
                                                <form>
                                                    <div class="form-group">
                                                        <input type="text" name="name" id="name" [(ngModel)]="username"
                                                            placeholder="Your Name" class="form-control">
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="email" name="email" id="email" [(ngModel)]="email"
                                                            placeholder="Your email address" class="form-control">
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="number" name="mobile" id="mobile"
                                                            [(ngModel)]="mobile" placeholder="Your Mobile Number"
                                                            class="form-control">
                                                    </div>

                                                    <div class="form-group">
                                                        <input type="password" name="password" id="password"
                                                            [(ngModel)]="password" placeholder="Create a password"
                                                            class="form-control">
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" name="ConfirmPassword"
                                                            id="ConfirmPassword" [(ngModel)]="password"
                                                            placeholder="Confirm Password" class="form-control">
                                                    </div>

                                                    <button type="submit" class="btn btn-primary"
                                                        (click)="postRegisterData()">Sign Up</button>

                                                </form>
                                                <!--form end-->
                                            </div>
                                            <div class="modal-footer">
                                                <a href="#">Already have account</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!---Modal end-->
                                <!-- Modal login-->
                                <div class="modal fade" id="myLogin" role="dialog">
                                    <div class="modal-dialog">
                                        <!-- Modal content-->
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title">Sign in</h4>
                                                <button type="button" class="close"
                                                    data-dismiss="modal">&times;</button>
                                            </div>
                                            <div class="modal-body">
                                                <!--form-->
                                                <form>
                                                    <div class="form-group">
                                                        <input type="email" name="loginemail" id="email"
                                                            [(ngModel)]="loginemail" placeholder="Your email address"
                                                            class="form-control">
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" name="loginPassword" id="password"
                                                            [(ngModel)]="loginPassword" placeholder="Password"
                                                            class="form-control">
                                                    </div>
                                                    <button type="submit" (click)="postLoginData()"
                                                        class="btn btn-primary">Sign In</button>
                                                </form>
                                                <!--form end-->
                                            </div>
                                            <div class="modal-footer">

                                                <a href="#">Forgot Password</a>|<a href="#">Create an Account</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--modal end-->
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="eventDetails && eventDetails.isApproved != 0">
                    <div class="row" *ngFor="let documentSection of documentDetails">
                        <div class="col-lg-12 col-md-12 margin-top">
                            <section>
                                <div class="container-fluid">
                                    <div class="overview-box">
                                        <div class="overview-content">
                                            <div class="content left-content">
                                                <h2 class="widget-title">{{documentSection.documentSection}}</h2>
                                                <div class="bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div  class="container">
                                        <div class="featured-boxes-inner">
                                            <div class="row m-0">

                                                <div class="col-lg-3 col-sm-6 col-md-6 p-0"
                                                    *ngFor="let sectionDetails of documentSection.documentSectionDetails">
                                                    <div class="single-featured-box">
                                                        <div [ngClass]="documentSection.class">
                                                            <i class="flaticon-envelope-of-white-paper"></i>
                                                        </div>
                                                        <h3>{{sectionDetails.documentTitle}}</h3>
                                                        <p>{{sectionDetails.documentDescription}}
                                                        </p>
                                                        <a style="cursor: pointer;" (click)="DownloadFile(sectionDetails.documentUpload)" class="read-more-btn">Download</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!---------end--->
            </div>
            <div class="tab-pane fade" id="entitlement" role="tabpanel" aria-labelledby="entitlement-tab">
                <br>
                <!---start Holding--->
                <section class="comparisons-area ptb-70 bg-f7fafd">
                    <div class="container">
                        <div class="section-title">
                            <h2>Entitlements</h2>
                            <div class="bar"></div>
                           <p>
                     Registered users can view their new entitlements here.
                           </p>
                           
                        </div>
                        


                        <div class="comparisons-table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Beneficiary Name</th>
                                        <th scope="col">i2 ID</th>
                                        <th scope="col">Amount</th>
                                       <th scope="col">Entitlement</th>
                                        
                                    </tr>
                                </thead>

                                <tbody *ngIf="holdingDetailsList">
                                    <tr *ngFor="let holder of holdingDetailsList">
                                        <td>{{holder.holdingName}}</td>
                                        <td>{{holder.uniqueNo}}</td>
                                        <td>{{holder.totalAmount}}</td>
                                        
                                        <td style="width: 45%;">
                                          <span *ngIf="holder.holdingEntitlement!=''">
                                                {{holder.holdingEntitlement.substring(0,50)}}         
                                          <a href="#" *ngIf="holder.holdingEntitlement.length >50" data-toggle="modal"
                                            data-target="#myEntitlement"  (click)="ShowEntitlement(holder.holdingEntitlement)" style="cursor: pointer;color:blue" > ...<u>more</u></a>
                                          </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <!--end holding--->
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <br>
                <!---start Holding--->
                <section class="comparisons-area ptb-70 bg-f7fafd">
                    <div class="container">
                        <div class="section-title">
                            <h2>Manage Beneficiary</h2>
                            <div class="bar"></div>
                            <p>
                               <!---Check your participation status. Register to manage your holdings and transactions here-->
                               Registered users can manage their holdings and related transactions here. 

                                </p>
                                <p>Check your Event Participation status and Action log below.</p>
                                <br>
                                <p style="font-weight: 900;"><a href="#" data-toggle="modal" data-target="#myFaq" ><u>FAQ's</u></a></p><br>
                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                data-target="#myEnroll" (click)="AddHolding()" *ngIf="eventDetails.upcoming == true && eventDetails.start_event == true">Add Beneficiary/Holding</button>
                        </div>

                        <div class="comparisons-table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Beneficiary Name</th>
                                        <th scope="col">i2 ID</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Event Participation</th>
                                        <th style="width: 300px;" scope="col" *ngIf="eventDetails.upcoming == true && eventDetails.start_event == true">Action</th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="holdingDetailsList">
                                    <tr *ngFor="let holder of holdingDetailsList">
                                        <td>{{holder.holdingName}}</td>
                                        <td>{{holder.uniqueNo}}</td>
                                        <td>{{holder.totalAmount}}</td>
                                        <td>
                                            <span title="{{holder.rejectionReason}}" *ngIf="holder.HoldingStatus == 'Rejected'"
                                                class="alert alert-danger" style="padding: 8px 18px 8px 18px;width:120px">{{holder.HoldingStatus}}</span>
                                            <span *ngIf="holder.HoldingStatus == 'Pending'"
                                                class="alert alert-warning" style="padding: 8px 20px 8px 20px;width:120px">{{holder.HoldingStatus}}</span>
                                            <span *ngIf="holder.HoldingStatus == 'Approved'"
                                                class="alert alert-success" style="padding: 8px 15px 8px 15px;width:120px">{{holder.HoldingStatus}}</span>
                                        </td>
                                        <td *ngIf="eventDetails.upcoming == true && eventDetails.start_event == true">
                                            <a *ngIf="holder.HoldingStatus != 'Approved'"  data-toggle="modal"
                                            data-target="#myEnroll" style="cursor: pointer;" (click)="ShowHolding(holder.holdingId)" class="btn btn-info" style="font-size: 10px;padding: 8px 8px 8px 8px;color: white;width:90px">Edit</a>
                                            <a *ngIf="holder.HoldingStatus == 'Approved'"  data-toggle="modal"
                                            data-target="#myUploadDoc" style="cursor: pointer;" (click)="ShowHoldingDocument(holder.holdingId,holder.HoldingClearingsystem)" class="btn btn-primary" style="font-size: 10px;padding: 8px 8px 8px 8px;color: white;width:90px">Uploads </a>&nbsp;
                                            <a *ngIf="holder.HoldingStatus == 'Approved'"  data-toggle="modal"
                                            data-target="#myTradeInfo" style="cursor: pointer;" (click)="ShowHoldingTradeInfo(holder.holdingId,holder.totalAmount,holder.uniqueNo)" class="btn btn-success" style="font-size: 10px;padding: 8px 8px 8px 8px;color: white;width:90px"> Trade Info</a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <!--end holding--->
            </div>
        </div>
    </div>
</section>
<!-- Modal Enroll-->
<div class="modal fade" style="z-index: 8888;" id="myEnroll" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Participation Form</h4>
                <button type="button" class="close" #closebutton id="closeModel" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <!--form-->
                <form [formGroup]="uploadHoldingData" (ngSubmit)="onSubmit()">                    
                    <div class="form-group">
                        <input type="hidden" name="eventType" [(ngModel)]="eventDetails.eventType"
                            formControlName="eventType" class="form-control">
                    </div>
                    <div class="row">
                        <div class="col-6">
                            
                            <div class="form-group">
                                <label> Beneficiary name including joint names</label>
                                <input type="text" name="holderName" [(ngModel)]="holderName" id="holderName" [placeholder]="eventDetails.eventType == 'Holder'?'Insert beneficiary name':'Insert holder\'s name'"
                                    formControlName="holderName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.holderName.errors }">
                                    <small id="emailHelp" class="form-text text-muted"><b>In case of joint holder add name separated with comma</b></small>
                                    <div *ngIf="submitted && f.holderName.errors" class="invalid-feedback" style="color:red">
                                        <div *ngIf="f.holderName.errors.required" style="color:red">Name is required</div>
                                    </div>

                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="holderEmail" [(ngModel)]="holderEmail" id="holderEmail" placeholder="Insert email id"
                                    formControlName="holderEmail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.holderEmail.errors }">  
                                    <div *ngIf="submitted && f.holderEmail.errors" class="invalid-feedback" style="color:red">
                                        <div *ngIf="f.holderEmail.errors.required" style="color:red">Email is required</div>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submitted && f.holderEmail.invalid && (f.holderEmail.dirty || f.holderEmail.touched)" style="color:red">Please enter valid email</div>                                  
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group view">
                                <label>Mobile number including country code</label>
                                <div class="row">
                                    <div class="col-5" style="padding-right: 0;">
                                      
                                        <!--<select name="holderCountryPhoneCode" id="holderCountryPhoneCode" [(ngModel)]="holderCountryPhoneCode" class="form-control" formControlName="holderCountryPhoneCode">
                                            <option *ngFor="let cdialcode of countryCodeDetails">
                                                {{cdialcode.phoneCode}}</option>
                                        </select>-->
                                           <select name="holderCountryPhoneCode" id="holderCountryPhoneCode" [(ngModel)]="holderCountryPhoneCode" class="form-control" formControlName="holderCountryPhoneCode" [ngClass]="{ 'is-invalid': submitted && f.holderCountryPhoneCode.errors }">
                                            <option value="">Select</option>
                                            <option *ngFor="let cdialcode of countryCodeDetails"> 
                                                {{cdialcode.phoneCode}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.holderCountryPhoneCode.errors" class="invalid-feedback" style="color:red">
                                            <div *ngIf="f.holderCountryPhoneCode.errors.required" style="color:red">Country Code is required</div>
                                        </div>

                                    </div>
                                    <div class="col-7" style="padding-left:0;">
                                        <input type="text" name="holderMobile" [(ngModel)]="holderMobile" id="holderMobile" formControlName="holderMobile"
                                        class="form-control" placeholder="Insert mobile number" [ngClass]="{ 'is-invalid': submitted && f.holderMobile.errors }">
                                        <!--<small id="" class="form-text text-muted"><b>Enter Mobile no. with Country Code e.g +44 7718340415</b></small>-->
                                        <div *ngIf="submitted && f.holderMobile.errors" class="invalid-feedback" style="color:red">
                                            <div *ngIf="f.holderMobile.errors.required" style="color:red">Contact number is required</div>
                                        </div>
                                        <div class="invalid-feedback" *ngIf="submitted && f.holderMobile.invalid && (f.holderMobile.dirty || f.holderMobile.touched)" style="color:red">Please enter number only</div>                                  
                                        
                                     </div>

                                </div>
                                
                                
                               
 
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Select Country</label>
                                <select name="holderCountry" id="holderCountry" [(ngModel)]="holderCountry" class="form-control" formControlName="holderCountry" [ngClass]="{ 'is-invalid': submitted && f.holderCountry.errors }">
                                    <option value="">Select Country</option>
                                    <option *ngFor="let country of countryDetails">
                                        {{country.countryName}}</option>
                                </select>
                                <div *ngIf="submitted && f.holderCountry.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="f.holderCountry.errors.required" style="color:red">Country is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        
                        <div class="col-6">
                            <div class="form-group">
                                <label>Principal Amount</label>
                                <input type="text" name="holderAmount" [(ngModel)]="holderAmount" id="holderAmount" formControlName="holderAmount"
                                    class="form-control" placeholder="Insert amount" [ngClass]="{ 'is-invalid': submitted && f.holderAmount.errors }">
                                    <div *ngIf="submitted && f.holderAmount.errors" class="invalid-feedback" style="color:red">
                                        <div *ngIf="f.holderAmount.errors.required" style="color:red">Amount is required</div>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submitted && f.holderAmount.invalid && (f.holderAmount.dirty || f.holderAmount.touched)" style="color:red">Please enter number only</div>                                  
                            </div>                            
                        </div>
                        <div class="col-6" *ngIf="eventDetails && eventDetails.eventType == 'Lender'">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Lender'">
                                <label><br/><br/><br></label>
                                <label><input type="checkbox" formControlName="cb" id="cb"> 
                                    <a href="termscondition/{{eventDetails.eventId}}" target="_blank"> 
                                        I have read and accept the Terms and Conditions</a>
                                </label>
                                   
                                <div *ngIf="submitted && uploadHoldingData.hasError('required', 'cb')"  style="color: red;">
                                   <div style="margin-top:0.25rem;font-size:80%;color:red"> Please accept terms & conditions</div>
                                </div>
                               <!--- <div (click)="showTCFlag=!showTCFlag" [innerHTML]="showTCFlag?'Hide terms and conditions':'Show terms and conditions'"></div>-->

                               
                            </div>
                          
                        </div>
                        <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label>Select Clearing System</label>
                                <select name="holderClearingSystem" id="holderClearingSystem" class="form-control" [(ngModel)]="holderClearingSystem"
                                    formControlName="holderClearingSystem" [ngClass]="{ 'is-invalid': submitted && f.holderClearingSystem.errors }">
                                    <option value="">Select</option>
                                    <option *ngFor="let clearingSystem of clearingSystemArr" [value]="clearingSystem">
                                        {{clearingSystem}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.holderClearingSystem.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="f.holderClearingSystem.errors.required" style="color:red">Clearing system is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        
                        <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label>Select ISIN number</label>
                                <select name="holderISIN" id="holderISIN" [(ngModel)]="holderISIN" class="form-control" 
                                formControlName="holderISIN" [ngClass]="{ 'is-invalid': submitted && f.holderISIN.errors }">
                                    <option value="">Select</option>
                                    <option *ngFor="let holdingNumber of holdingNumberArr" [value]="holdingNumber">
                                        {{holdingNumber}}</option>
                                </select>
                                <div *ngIf="submitted && f.holderISIN.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="f.holderISIN.errors.required" style="color:red">ISIN is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label>Clearing System Account Number</label>
                                <input type="text" [(ngModel)]="holderClearingSystemAccount" name="holderClearingSystemAccount" id="holderClearingSystemAccount" 
                                placeholder="Insert clearing system account number"
                                    formControlName="holderClearingSystemAccount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.holderClearingSystemAccount.errors }">
                                    <div *ngIf="submitted && f.holderClearingSystemAccount.errors" class="invalid-feedback" style="color:red">
                                        <div *ngIf="f.holderClearingSystemAccount.errors.required" style="color:red">Account number is required</div>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="submitted && f.holderClearingSystemAccount.invalid && (f.holderClearingSystemAccount.dirty || f.holderClearingSystemAccount.touched)" style="color:red">Please enter number only</div>                                  
                            </div>
                        </div>
                       
                    </div>
                    <div class="row">
                        <!-- <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label>Proof of Holding document *(PDF, JPEG or PNG)</label>
                                <input type="file" name="profile" accept="application/pdf,image/png,image/jpeg,image/jpg" id="profile" (change)="onFileSelected($event)" formControlName="profile" [ngClass]="{ 'is-invalid': submitted && f.profile.errors }">
                                <small id="emailHelp" class="form-text text-muted" *ngIf="isEditHolder"><a style="cursor: pointer;" (click)="DownloadHolderFile()">Download</a></small>
                                <div *ngIf="submitted && f.profile.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="f.profile.errors.required" style="color:red">Document is required</div>
                                </div> 
                                <div class="invalid-feedback" *ngIf="submitted && f.profile.invalid && (f.profile.dirty || f.profile.touched)" style="color:red">Invalid File Type</div>                                  
                               
                            </div>

                        </div> -->
                        <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label>Proof of Holding document *(PDF, JPEG or PNG)</label>
                                <input type="file" name="profile" accept="application/pdf,image/png,image/jpeg,image/jpg" id="profile" (change)="onFileSelected($event)" formControlName="profile">
                                    <div *ngIf="fileext()">
                                        <small id="emailHelp" class="form-text text-muted" *ngIf="isEditHolder"><a style="cursor: pointer;" (click)="DownloadHolderFile()">Download</a></small>
                                    </div>
                                
                               
                            </div>

                        </div>
                        <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label><input type="checkbox" formControlName="cb" id="cb"> 
                                    <a href="termscondition/{{eventDetails.eventId}}" target="_blank"> 
                                        I have read and accept the Terms and Conditions</a>
                                </label>
                                   
                                <div *ngIf="submitted && uploadHoldingData.hasError('required', 'cb')"  style="color: red;">
                                   <div style="margin-top:0.25rem;font-size:80%;color:red"> Please accept terms & conditions</div>
                                </div>
                               <!--- <div (click)="showTCFlag=!showTCFlag" [innerHTML]="showTCFlag?'Hide terms and conditions':'Show terms and conditions'"></div>-->

                               
                            </div>
                          
                        </div>
                        
                    </div>
                    
                   <!--- <div class="row" *ngIf="!isEditHolder">
                        <div class="col-6">
                            <div class="form-group" *ngIf="eventDetails && eventDetails.eventType == 'Holder'">
                                <label><input type="checkbox" formControlName="cb" id="cb"> <a href="policy" target="_blank"> 
                                    I have read and accept the Terms and Conditions</a></label>
                                <div style="color: red;" *ngIf="submitted && uploadHoldingData.hasError('required', 'cb')">
                                    Please accept terms and conditions
                                </div>
                                
                                <label><input type="checkbox"
                                    [checked]="TC"
                                    (change)="TC = !TC" name="TC" id="TC"                                     
                                    [ngClass]="{ 'is-invalid': submitted && !TC }">                                        
                                    I have read and accept the Terms and Conditions </label>
                                    <div *ngIf="submitted && !TC" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545">
                                        <div style="color:red">Please accept terms and conditions</div>
                                    </div>
                                    <div (click)="showTCFlag=!showTCFlag" [innerHTML]="showTCFlag?'Hide terms and conditions':'Show terms and conditions'"></div>
                            </div>
                          
                        </div>
                        
                    </div>-->
                   
                    
                    <div class="row" *ngIf="showTCFlag">
                        <div class="col-12" style="max-height: 150px; overflow-y: scroll;" [innerHTML]="eventDetails.termsCondition">
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>

                </form>
                <!--form end-->
            </div>
           
        </div>

    </div>
</div>
<!---Modal end-->


<!-- End Blog Details Area -->

<!-- Modal Enroll-->
<div class="modal fade" style="z-index: 8888;" id="myUploadDoc" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Upload Document</h4>
                <button type="button" class="close" #closeDocButton id="closeModel" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="uploadHoldingDocument" (ngSubmit)="onSubmitDcoument()">                    
                   

                    <div class="row">
                        <div class="col-3">
                            <label>Document Name</label>
                        </div>
                        <div class="col-6">
						   <div class="form-group">
                            
							 <input type="text" name="holderDocName" id="holderDocName" formControlName="holderDocName" class="form-control" [ngClass]="{ 'is-invalid': submittedDocument && g.holderDocName.errors }">
                             <div *ngIf="submittedDocument && g.holderDocName.errors" class="invalid-feedback" style="color:red">
                                <div *ngIf="g.holderDocName.errors.required" style="color:red">Document Name is required</div>
                            </div>
                            
                            </div> 
                          
                        </div>
                    </div> 
                    
                    <div class="row" *ngIf="eventDetails.special_event == 1">
                        <div class="col-3">
                            <label>Custody Instruction Reference Number</label>
                        </div>
                        <div class="col-6">
						   <div class="form-group">
                            
							 <input type="text" name="swiftCode" id="swiftCode"  class="form-control">
                             <small id="clr"></small>
                            </div> 
                          
                        </div>
                    </div> 
                   <div class="row">
                        <div class="col-3">
                            <label>Upload Document *(PDF, JPEG, PNG, Excel(.xls/.xlsx) )</label>
                        </div>
						<div class="col-6">
                            <div class="form-group">
                               
                                <input type="file" accept="application/pdf,image/png,image/jpeg,image/jpg" name="docfileupload" id="docfileupload" (change)="onFileSelectedUpload($event)" formControlName="docfileupload" [ngClass]="{ 'is-invalid': submittedDocument && g.docfileupload.errors }">
                                <div *ngIf="submittedDocument && g.docfileupload.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="g.docfileupload.errors.required" style="color:red">Document is required</div>
                                </div>
                                <div class="invalid-feedback" *ngIf="submittedDocument && g.docfileupload.invalid && (g.docfileupload.dirty || g.docfileupload.touched)" style="color:red">Invalid File Type</div>                                  
                            </div>

                        </div>
                        <input type="hidden" id="holding_id"> 
                        <input type="hidden" *ngIf="eventDetails.special_event == 0" name="swiftCode" id="swiftCode" class="form-control">
                        
                    </div>
                  <div style="text-align: center;">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div> 

                  </form>
                  <br>	
                  <div class="table-responsive">
                    <table class="table">	
                    <thead>
                        <tr>
                            <th scope="col">Document Name</th>
                            <th scope="col">Uploaded</th>
                            <th>Upload Date</th>
                           <!---<th scope="col">Swift Code</th>-->
                            <th scope="col">Status</th>
                          
                    </thead>

                    <tbody *ngIf="holdingDocumentDetailsList">
                        <tr *ngFor="let doc of holdingDocumentDetailsList">
                            <td>{{doc.documentName}}</td>
                            <td>
                                <a style="cursor: pointer;" (click)="DownloadholdingDocument(doc.id)" class="read-more-btn"><i class="fa fa-download" aria-hidden="true"></i>
                                </a> {{doc.documentUpload}}  
                            </td>
                            <td>{{doc.created_time}}</td>
                            <!--<td>{{doc.swiftCode}}</td>-->
                            <td>
                                <span title="{{doc.reason}}" *ngIf="doc.status == 'Rejected'"
                                    class="alert alert-danger" style="padding: 8px 18px 8px 18px;width:120px">{{doc.status}}</span>
                                <span *ngIf="doc.status == 'Pending'"
                                    class="alert alert-warning" style="padding: 8px 20px 8px 20px;width:120px">{{doc.status}}</span>
                                <span *ngIf="doc.status == 'Approved'"
                                    class="alert alert-success" style="padding: 8px 15px 8px 15px;width:120px">{{doc.status}}</span>
                            </td>
                            
                        </tr>
                        <tr *ngIf="this.holdingDataDocument.count==0" style="text-align: center;">
                        
                            <td colspan="4">Your uploads will be shown here</td>
                        
                    </tr>

                    </tbody>
                    
                </table>
                </div>

                
                <!--form end-->
            </div>
           
        </div>

    </div>
</div>
<!---Modal end-->

<!-- Modal Enroll-->
<div class="modal fade" style="z-index: 8888;" id="myTradeInfo" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Trade Info</h4>
                <button type="button" class="close" #closeTradeButton id="closeModel" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="uploadTradeInfo" (ngSubmit)="onSubmitTradeInfo()">                    
  
                    <div class="row">
                        <div class="col-4"><label>Select Trade</label>
                        </div>
                        <div class="col-6">
						   <div class="form-group">
                            
                             <div>
                                <input id="Buy" type="radio"  [(ngModel)]="infoType" checked value="Bought" name="infoType" formControlName="infoType" [ngClass]="{ 'is-invalid': submittedTradeInfo && frm.infoType.errors }">
                                <label for="Buy"> &nbsp;Bought</label> &nbsp;&nbsp;&nbsp;
                                <input id="Sale" type="radio"  [(ngModel)]="infoType" value="Sold" name="infoType" formControlName="infoType">
                                <label for="Sale"> &nbsp;Sold</label>
                                
                                 <div *ngIf="submittedTradeInfo && myForm.errors?.required" class="invalid-feedback">
                                    <div style="color:red">Please select either value</div>
                                 </div>
                             </div>
                            <!---- <div *ngIf="frm.infoType.touched && frm.infoType.invalid" class="alert alert-danger">
                                <div *ngIf="frm.infoType.errors.required">Type is required.</div>
                            </div>-->
                
							<!--<select class="form-control" name="infoType" id="infoType" formControlName="infoType" [ngClass]="{ 'is-invalid': submittedTradeInfo && frm.infoType.errors }">
                                <option value="">Select</option>
                                <option value="Buy">Buy</option>
                                <option value="Sale">Sale</option>
                            </select>-->
                           <!--- <div *ngIf="submittedTradeInfo && frm.infoType.errors" class="invalid-feedback" style="color:red">
                                <div *ngIf="frm.infoType.errors.required" style="color:red">Type is required</div>
                            </div>-->
                            </div> 
                          
                        </div>
                    </div> 
                    
                    <div class="row">
                        <div class="col-4">
                            <label>Counter Party's Name</label>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                
                                <input type="text" class="form-control" name="infoName" id="infoName" formControlName="infoName" [ngClass]="{ 'is-invalid': submittedTradeInfo && frm.infoName.errors }" >
                                <div *ngIf="submittedTradeInfo && frm.infoName.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="frm.infoName.errors.required" style="color:red">Name is required</div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Counter Party's Unique Number</label>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                
                                <input type="text" class="form-control" name="infoUniqueNo" id="infoUniqueNo" formControlName="infoUniqueNo" [ngClass]="{ 'is-invalid': submittedTradeInfo && frm.infoUniqueNo.errors }" >
                                <div *ngIf="submittedTradeInfo && frm.infoUniqueNo.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="frm.infoUniqueNo.errors.required" style="color:red">Unique No. is required</div>
                                </div>
                                <div *ngIf="frm.infoUniqueNo.touched && frm.infoUniqueNo.invalid" class="invalid-feedback" style="color:red">
                                    <div *ngIf="frm.infoUniqueNo.errors.cannotContainSpace" style="color:red">Cannot contain space </div>
                                </div>
                        
                            </div>

                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Principal Amount Traded</label>
                        </div>
                        <div class="col-6">
						   <div class="form-group">
                                <input type="text" class="form-control" name="infoAmount" id="infoAmount" formControlName="infoAmount" [ngClass]="{ 'is-invalid': submittedTradeInfo && frm.infoAmount.errors }">
                                <div *ngIf="submittedTradeInfo && frm.infoAmount.errors" class="invalid-feedback" style="color:red">
                                    <div *ngIf="frm.infoAmount.errors.required" style="color:red">Amount is required</div>
                                </div>
                                <div class="invalid-feedback" *ngIf="submittedTradeInfo && frm.infoAmount.invalid && (frm.infoAmount.dirty || frm.infoAmount.touched)" style="color:red">Please enter number only</div>                                  
                                <div *ngIf="sumittedAmount && sumittedAmount==true " style="color:red">Amount should be less than Actual Amount</div>                                  
                            </div> 
                          
                        </div>
						
                    </div>
                    
                   <div class="row">
                    <div class="col-4">
                        <label>Document Name</label>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                        
                            <input type="text" class="form-control" name="infoFileName" id="infoFileName" formControlName="infoFileName">
                           
                        </div>

                    </div>
                    
                </div>
                     
                   <div class="row">
                        <div class="col-4">
                            <label>Upload Document *(PDF, JPEG, or PNG)</label>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                            
                                <input type="file" accept="application/pdf,image/png,image/jpeg,image/jpg" name="infoFileUpload" id="infoFileUpload" (change)="onInfoFileSelectedUpload($event)" formControlName="infoFileUpload">
                                <div *ngIf="frm.infoFileUpload.touched && frm.infoFileUpload.invalid" class="">
                                    <div *ngIf="frm.infoFileUpload.errors.notSupportedFileType" style="color:red">Invalid File Type</div>
                                </div>
                        
                               <!---- <div class="invalid-feedback" *ngIf="submittedTradeInfo && frm.infoFileUpload.invalid && (frm.infoFileUpload.dirty || frm.infoFileUpload.touched)" style="color:red">Invalid File Type</div>-->                                  
                                
                                
                            </div>

                        </div>
                        
                    </div>
                    <input type="hidden" id="holdingIdInfo">
                    <input type="hidden" id="amountCompare">
                    <input type="hidden" id="myUnique">
                  <div style="text-align: center;">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div> 

                  </form>
                  
                  <br>	
                  <div class="table-responsive">
                   
                  <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Trade</th>
                            <th scope="col">Counter Party</th>
                          <!---- <th scope="col">Holding ID</th>-->
                            <th scope="col">Amount</th>
                            <th scope="col">Document</th>
                            <!--<th scope="col">Document Download</th>-->
                            <th scope="col">Submission by</th>
                            <th scope="col">Status</th>
                          
                    </thead>

                    <tbody *ngIf="tradeInfoList">
                        <tr *ngFor="let doc of tradeInfoList">
                            <td>{{doc.type}}</td>
                            <td>{{doc.counterPartyName}} ({{doc.uniqueNo}})</td>
                            <!--<td>{{doc.uniqueNo}}</td>-->
                            <td>{{doc.amount}}</td>
                            <td>
                             <a style="cursor: pointer;" class="read-more-btn" *ngIf="doc.documentUpload!=''" (click)="DownloadInfo(doc.id)"> <i class="fa fa-download" aria-hidden="true"></i>
                                </a> 
                                {{doc.documentName}} 
                                </td>
                           <!--- <td>
                                <a style="cursor: pointer;" class="read-more-btn" *ngIf="doc.documentUpload!=''" (click)="DownloadInfo(doc.id)"><i class="fa fa-download" aria-hidden="true"></i>
                                </a>
                            </td>-->
                            <td>{{doc.tradeby}}</td>
                            <td>
                                <span title="{{doc.reason}}" *ngIf="doc.status == 'Rejected'"
                                    class="alert alert-danger" style="padding: 8px 17px 8px 17px;width:120px">{{doc.status}}</span>
                                <span *ngIf="doc.status == 'Pending'"
                                    class="alert alert-warning" style="padding: 8px 19px 8px 19px;width:120px">{{doc.status}}</span>
                                <span *ngIf="doc.status == 'Approved'"
                                    class="alert alert-success" style="padding: 8px 15px 8px 15px;width:120px">{{doc.status}}</span>
                            </td>
                            
                        </tr>
                        <tr *ngIf="this.tradeInfo.count==0" style="text-align: center;">
                        
                            <td colspan="6">Your submissions will be shown here</td>
                        
                    </tr>

                    </tbody>
                </table>
                </div>		
                
                
                <!--form end-->
            </div>
           
        </div>

    </div>
</div>
<!---Modal end-->



<!-- Modal Enroll-->
<div class="modal fade" style="z-index: 8888;" id="myEntitlement" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Entitlement</h4>
                <button type="button" class="close" #closeTradeButton id="closeModel" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p id="ent"></p>
                
            
            </div>
           
        </div>

    </div>
</div>
<!---Modal end-->

<!-- Modal Enroll-->
<div class="modal fade" style="z-index: 8888;" id="myFaq" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">FAQ</h4>
                <button type="button" class="close" #closeTradeButton id="closeModel" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <section class="faq-area">
                    <div class="container">
                        <div class="row">
                            
                            <div class="col-lg-12 col-md-12">

                                <p>
                                   <b>Holding Information</b><br>
                                   Your holding(s) detail from your <u>Participation Form</u> is set out in the <u>My Holdings</u> section. If you are participating in an event and have separate (e.g. Custodian or Account Holder) or multiple (e.g. Instutional Investor or Investment Manager) holdings, add here. 
                                </p>
                                   <hr> 
                                <p>
                                   <b> Action Information (Uploads/Trades)</b><br>
                                    If the event you are participating in requires you to deliver documents to i2 Capital (e.g. Account Holder Letter, Scheme Creditor and/or Securities Confirmation), select <u>Uploads</u> to submit document(s) required. For separate or multiple holdings, upload a separate document for each holding as required in the offering statement.

                                </p>
    
                             
                               
                            </div>
                        </div>
                
                       
                    </div>
                </section>
                
                
            
            </div>
           
        </div>

    </div>
</div>
<!---Modal end-->