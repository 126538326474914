import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from '../../common/GlobalConstants';
import { TmplAstRecursiveVisitor } from '@angular/compiler';
import * as forge from 'node-forge';

declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({
    // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Authorization': 'ApiAuth xyz'
  })
}

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closeDocButton') closeDocButton;
  @ViewChild('closeTradeButton') closeTradeButton;


  eventDetailsData: any;
  eventDetails: any;
  countryDetailsData: any;
  countryDetails: any;
  countryCodeDetailsData: any;
  countryCodeDetails: any;

  username: any;
  email: any;
  mobile: any;
  password: any;
  loginPassword: any;
  loginemail: any;
  holdingNumberArr: any;
  clearingSystemArr: any;
  holderId: any;
  holderName: any;
  holderEmail: any;
  holderMobile: any;
  holderAmount: any;
  holderClearingSystemAccount: any;
  holderClearingSystem: any;
  holderISIN: any;
  holdingDocument: any;
  TC: boolean;
  showTCFlag: boolean;
  documentDetails: any;
  selectedFile: any;
  holdingData: any;
  holdingDetailsList: any;
  holdingDataDocument: any;
  holdingDocumentDetailsList: any;
  tradeInfo: any
  tradeInfoList: any;
  loggin = false;
  eventType: any;
  filePath: any;
  checkType: any;
  isEditHolder: any;
  updatect = 'us';
  country: any;
  holderCountry: any;
  docfileupload: any;
  holderDocName: any;
  //infoType:any;
  infoUniqueNo: any;
  infoAmount: any
  sumittedAmount = false;
  infoType: any;
  infoName: any;
  infoFileName: any;
  infoFileUpload: any;
  holderCountryPhoneCode: any;
  ext:string;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private ref: ChangeDetectorRef) { }
  uploadHoldingData: FormGroup;
  uploadHoldingDocument: FormGroup;
  uploadTradeInfo: FormGroup;
  submitted = false;
  submittedDocument = false;
  submittedTradeInfo = false;


  ngOnInit(): void {

    this.getEventDetails();
    this.countryList();
    this.countryCodeList();

    this.loggin = sessionStorage.getItem('userId') ? true : false;
    this.isEditHolder = false;
    this.filePath = GlobalConstants.apiURL + 'uploads/'
    this.setValidationForm();
    this.validateDocForm();
    this.validateTradeForm();
    // this.infoType="Buy";

  }


  setValidationForm() {
    if (this.activatedRoute.snapshot.params['type'] == 'Lender') {
      this.uploadHoldingData = this.formBuilder.group({
        profile: [''],
        //   profile: this.isEditHolder?['']:['', [Validators.required]],
        holderName: ['', [Validators.required]],
        holderEmail: ['', [Validators.required, this.emailValidator]],
        //  holderMobile: ['', [Validators.required,Validators.pattern('^[+]\\d{12}$')]],
        // holderMobile: ['', [Validators.required,this.mobileValidator]],
        holderMobile: ['', [Validators.required, this.numberValidator]],
        holderISIN: [''],
        holderClearingSystem: [''],
        holderAmount: ['', [Validators.required, this.deciamlValidator]],
        holderClearingSystemAccount: [''],
        eventType: [''],
        holderCountry: ['', [Validators.required]],
        holderCountryPhoneCode: ['', [Validators.required]],
        cb: [false, Validators.requiredTrue],
      });
    }
    else {
      this.uploadHoldingData = this.formBuilder.group({
        profile: [''],
        //profile: this.isEditHolder?['',[this.checkExtension]]:['', [Validators.required,this.checkExtension]],
        holderName: ['', [Validators.required]],
        holderEmail: ['', [Validators.required, this.emailValidator]],
        //   holderMobile: ['', [Validators.required,Validators.pattern('^[+]\\d{12}$')]],
        //  holderMobile: ['', [Validators.required,this.mobileValidator]],
        holderMobile: ['', [Validators.required, this.numberValidator]],
        holderISIN: ['', [Validators.required]],
        holderClearingSystem: ['', [Validators.required]],
        holderAmount: ['', [Validators.required, this.deciamlValidator]],
        holderClearingSystemAccount: ['', [Validators.required, this.numberValidator]],
        eventType: [''],
        cb: [false, Validators.requiredTrue],
        holderCountry: ['', [Validators.required]],
        holderCountryPhoneCode: ['', [Validators.required]]
      });
    }
  }
  validateDocForm() {
    this.uploadHoldingDocument = this.formBuilder.group({
      holderDocName: ['', [Validators.required]],
      docfileupload: ['', [Validators.required, this.checkExtensionUpload]]


    });
  }
  validateTradeForm() {
    this.uploadTradeInfo = this.formBuilder.group({
      infoName: ['', [Validators.required]],
      infoUniqueNo: ['', [Validators.required, this.cannotContainSpace]],
      infoAmount: ['', [Validators.required, this.deciamlValidator]],
      infoType: ['', [Validators.required]],
      infoFileUpload: ['', [this.checkExtension]],
      infoFileName: []

    });
  }

  ShowHolding(id) {

    this.AddHolding();

    this.ref.detectChanges();

    this.submitted = false;
    this.isEditHolder = true;
    let obj = this.holdingDetailsList.find(o => o.holdingId === id);
    //console.log(obj);
    this.holderName = obj.holdingName;
    this.holderEmail = obj.HoldingEmail;
    this.holderMobile = obj.HoldingContact;
    this.holderAmount = obj.HoldingAmount;
    this.holderClearingSystemAccount = obj.HoldingAcountNumber;
    this.holderISIN = obj.holdingISIN;
    this.holderClearingSystem = obj.HoldingClearingsystem;
    // this.country=obj.countryName;
    this.holdingDocument = obj.HoldingDocument;
    this.updatect = obj.countryIso;
    this.holderCountry = obj.countryName;
    this.holderCountryPhoneCode = obj.countryDialCode;
    $("#profile").val("");
    this.holderId = id;
    this.setValidationForm();


    //alert(this.holderISIN);
    this.ref.detectChanges();
    // console.log(this.holdingDocument)
  }

  ShowHoldingDocument(id, data) {
    this.submittedDocument = false;
    this.holderDocName = "";
    this.docfileupload = "";
    this.validateDocForm();
    this.getHoldingDocument(id);
    $("#holding_id").val(id);
    if (data != '') {
      $("#clr").html("Enter Custody Instruction Reference Number for " + data);
    }


  }
  ShowEntitlement(id) {
    this.ref.detectChanges();
    $("#ent").html(id);

  }
  ShowHoldingTradeInfo(id, amt, unique) {
    this.submittedTradeInfo = false;
    this.infoName = "";
    this.infoUniqueNo = "";
    this.infoAmount = "";
    this.infoType = "";
    this.infoFileUpload = "";
    this.infoFileName = "";
    this.validateTradeForm();
    this.getTradeInfo(id, unique);
    $("#holdingIdInfo").val(id);
    $("#amountCompare").val(amt);
    $("#myUnique").val(unique);

  }
  getHoldingDocument(id) {
    this.http.get(GlobalConstants.apiURL + 'getHoldingDocument.php?holdingId=' + id + '&user_id=' + sessionStorage.getItem('userId'), httpOptions).subscribe(response => {
      this.holdingDataDocument = response;
      this.holdingDocumentDetailsList = this.holdingDataDocument.HoldingDocumenttDetailsList;
      //  alert(this.holdingDataDocument.count);

    })
  }
  getTradeInfo(id, unique) {
    this.http.get(GlobalConstants.apiURL + 'getTradeInfo.php?holdingId=' + id + '&user_id=' + sessionStorage.getItem('userId') + '&uniqueNo=' + unique, httpOptions).subscribe(response => {
      this.tradeInfo = response;
      this.tradeInfoList = this.tradeInfo.TradingInfoDetailsList;
      //console.log(response);
    })
  }

  get f() {
    return this.uploadHoldingData.controls;
  }
  get g() {
    return this.uploadHoldingDocument.controls;
  }
  get frm() {
    return this.uploadTradeInfo.controls;
  }
  get myForm() {
    return this.uploadTradeInfo.get('infoType');
  }

  numberValidator(control) {
    if (control.value) {
      const matches = control.value.match(/^[0-9]+$/);
      //const matches = control.value.match(/^[0-9]*\.?[0-9]*$/g);
      return matches ? null : { 'invalidNumber': true };
    } else {
      return null;
    }
  }


  mobileValidator(control) {
    if (control.value) {
      //const matches = control.value.match(/^\+?([+][0-9]{1,5}[ ])\)?[-. ]?([0-9]{4,16})$/);
      //const matches = control.value.match(/^\+?([+][0-9])\)?[]?([0-9]{4,16})$/);
      const matches = control.value.match(/^[+][0-9]*\ ?[0-9]*$/g);
      return matches ? null : { 'invalidNumber': true };
    } else {
      return null;
    }
  }

  deciamlValidator(control) {
    if (control.value) {
      const matches = control.value.match(/^[0-9]*\.?[0-9]*$/g);
      return matches ? null : { 'invalidNumber': true };
    } else {
      return null;
    }
  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }
  checkExtension(control) {
    if (control.value) {

      const matches = control.value.match(/([^\s])+(.png|.jpg|.pdf|.jpeg|.PNG|.PDF|.JPG|.JPEG)$/);
      return matches ? null : { 'notSupportedFileType': true };
    } else {
      return null;
    }

  }

  checkExtensionUpload(control) {
    if (control.value) {

      const matches = control.value.match(/([^\s])+(.png|.jpg|.pdf|.jpeg|.PNG|.PDF|.JPG|.JPEG|.xlsx|.xls|.XLSX|.XLS)$/);
      return matches ? null : { 'notSupportedFileType': true };
    } else {
      return null;
    }

  }

  cannotContainSpace(control) {
    if (control.value) {
      if ((control.value as string).indexOf(' ') >= 0) {
        return { cannotContainSpace: true }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }

  }


  ///////Enroll data start///
  onFileSelected(event) {
    const file = event.target.files[0];
    this.uploadHoldingData.get('profile').setValue(file);

  }
  onFileSelectedUpload(event) {
    const file = event.target.files[0];
    this.uploadHoldingDocument.get('docfileupload').setValue(file);

  }

  onInfoFileSelectedUpload(event) {
    const file = event.target.files[0];
    this.uploadTradeInfo.get('infoFileUpload').setValue(file);

  }
  onSubmitDcoument() {
    var rt = forge.pki.publicKeyFromPem(GlobalConstants.publicKey);
    this.submittedDocument = true;
    if (this.uploadHoldingDocument.invalid) {
      //alert('ddd');
      return;
    }
    else {
      const docdata = new FormData();

      docdata.append('doc_name', window.btoa(rt.encrypt(this.uploadHoldingDocument.get('holderDocName').value)));
      docdata.append('event_id', window.btoa(rt.encrypt(this.activatedRoute.snapshot.params['id'])));
      docdata.append('holding_id', window.btoa(rt.encrypt($("#holding_id").val())));
      docdata.append('swiftCode', window.btoa(rt.encrypt($("#swiftCode").val())));
      docdata.append('documentUpload', this.uploadHoldingDocument.get('docfileupload').value);
      docdata.append('user_id', window.btoa(rt.encrypt(sessionStorage.getItem('userId'))));
      this.http.post<any>(GlobalConstants.apiURL + 'addHoldingDocument.php', docdata).subscribe(response => {

        $("#holderDocName").val("");
        $("#docfileupload").val("");
        $("#swiftCode").val("");
        // this.getHoldingDocument($("#holding_id").val());
        // this.closeDocButton.nativeElement.click();


        this.submittedDocument = false;

        this.validateDocForm();
        this.getHoldingDocument($("#holding_id").val());
      })

    }
  }
  onSubmitTradeInfo() {
    var rs = forge.pki.publicKeyFromPem(GlobalConstants.publicKey);
    this.submittedTradeInfo = true;
    if (this.uploadTradeInfo.invalid) {
      return;
    }
    else {

      //if($("input[name='infoType']:checked").val()=='Sold' && (parseFloat($("#infoAmount").val())>parseFloat($("#amountCompare").val())) )
      if (this.uploadTradeInfo.get('infoType').value == 'Sold' && (parseFloat($("#infoAmount").val()) > parseFloat($("#amountCompare").val()))) {
        this.sumittedAmount = true;
      }
      else {

        this.sumittedAmount = false;
        const idata = new FormData();

        idata.append('type', window.btoa(rs.encrypt(this.uploadTradeInfo.get('infoType').value)));
        //  idata.append('type', $("input[name='infoType']:checked").val());
        idata.append('event_id', window.btoa(rs.encrypt(this.activatedRoute.snapshot.params['id'])));
        idata.append('holding_id', window.btoa(rs.encrypt($("#holdingIdInfo").val())));
        idata.append('amount', window.btoa(rs.encrypt(this.uploadTradeInfo.get('infoAmount').value)));
        idata.append('uniquenumber', window.btoa(rs.encrypt(this.uploadTradeInfo.get('infoUniqueNo').value)));
        idata.append('user_id', window.btoa(rs.encrypt(sessionStorage.getItem('userId'))));
        idata.append('myUnique', window.btoa(rs.encrypt($("#myUnique").val())));
        idata.append('infoName', window.btoa(rs.encrypt(this.uploadTradeInfo.get('infoName').value)));
        idata.append('docName', this.uploadTradeInfo.get('infoFileName').value);
        idata.append('docUpload', this.uploadTradeInfo.get('infoFileUpload').value);
        this.http.post<any>(GlobalConstants.apiURL + 'addTradeInfo.php', idata).subscribe(response => {
          // this.closeTradeButton.nativeElement.click();
          $("#infoAmount").val("");
          $("#infoUniqueNo").val("");
          $("#infoName").val("");
          $("input[name='infoType']:checked").val("");
          $("#infoFileName").val("");
          $("#infoFileUpload").val("");
          this.submittedTradeInfo = false;

          this.validateTradeForm();
          this.getTradeInfo($("#holdingIdInfo").val(), $("#myUnique").val());
          // this. validateTradeForm(); 
          //this.getHoldingData();
        })
      }
    }
  }
  onSubmit() {
    var rsa = forge.pki.publicKeyFromPem(GlobalConstants.publicKey);
    this.submitted = true;
    if (this.uploadHoldingData.invalid) {
      return;
    }
    else {
      const formdata = new FormData();

      formdata.append('b_name', window.btoa(rsa.encrypt(this.holderName)));
      formdata.append('b_email', window.btoa(rsa.encrypt(this.holderEmail)));
      formdata.append('b_contact', window.btoa(rsa.encrypt(this.holderMobile)));
      formdata.append('dialcode', window.btoa(rsa.encrypt($("#holderCountryPhoneCode").val())));
      // formdata.append('iso', $("#iso").val());
      formdata.append('country_name', window.btoa(rsa.encrypt(this.holderCountry)));
      formdata.append('b_amount', window.btoa(rsa.encrypt(this.holderAmount)));
      formdata.append('b_event_id', window.btoa(rsa.encrypt(this.activatedRoute.snapshot.params['id'])));
      formdata.append('user_id', window.btoa(rsa.encrypt(sessionStorage.getItem('userId'))));
      formdata.append('eventType', window.btoa(rsa.encrypt(this.checkType)));
      formdata.append('isEditHolder', this.isEditHolder);
      formdata.append('HolderID', window.btoa(rsa.encrypt(this.holderId)));
      if (this.activatedRoute.snapshot.params['type'] == 'Holder') {
        formdata.append('documentUpload', this.uploadHoldingData.get('profile').value);

        formdata.append('b_isin', window.btoa(rsa.encrypt(this.holderISIN)));
        formdata.append('b_clearing_system', window.btoa(rsa.encrypt(this.holderClearingSystem)));

        formdata.append('b_clearing_system_account', window.btoa(rsa.encrypt(this.holderClearingSystemAccount)));

      }
      // new Response(formdata).text().then(console.log)

      this.http.post<any>(GlobalConstants.apiURL + 'add_holding.php', formdata).subscribe(response => {
        $("#profile").val("");
        this.getHoldingData();
        this.closebutton.nativeElement.click();
        $("#profile-tab").prop("class", "nav-link active");
        $("#home-tab").prop("class", "nav-link");
        $("#profile").prop("class", "tab-pane fade show active");
        $("#home").prop("class", "tab-pane fade");
        this.isEditHolder = false;
      })
    }
  }

  onCountryChange(event) {
    $("#code_1").val("+" + event.dialCode);
    $("#iso").val(event.iso2);
    $("#country_name").val(event.name);

  }
  showTC() {
    this.showTCFlag = true;
  }
  AddHolding() {
    this.submitted = false;
    this.TC = false;
    this.showTCFlag = false;
    this.holderId = 0;
    this.isEditHolder = false;
    this.holderName = "";
    this.holderEmail = "";
    this.holderMobile = "";
    this.holderAmount = "";
    this.holderISIN = "";
    this.holderClearingSystemAccount = "";
    this.holderClearingSystem = "";
    this.holderISIN = "";
    this.holderClearingSystem = "";
    this.holderCountry = "";
    this.holderCountryPhoneCode = "";
    $("#profile").val("");
    this.setValidationForm();
  }
  RegisterNow() {
    this.router.navigate(['/signup']);
  }

  LoginNow() {
    this.router.navigate(['/signin']);
  }

  ManageBeneficiary() {
    this.isEditHolder = false;
    this.getHoldingData();
    $("#profile-tab").prop("class", "nav-link active");
    $("#home-tab").prop("class", "nav-link");
    $("#profile").prop("class", "tab-pane fade show active");
    $("#home").prop("class", "tab-pane fade");
  }

  ///Enroll data end here//
  DownloadHolderFile() {
    var element = document.createElement('a');
    element.setAttribute('href', GlobalConstants.apiURL + 'downloadHolderFile.php?userid=' + sessionStorage.getItem('userId') + '&fileName=' + this.holdingDocument);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  DownloadInfo(filename) {
    // alert("rita");
    var element = document.createElement('a');
    element.setAttribute('href', GlobalConstants.apiURL + 'downloadInfoDocument.php?id=' + filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  DownloadFile(filename) {
    var element = document.createElement('a');
    element.setAttribute('href', GlobalConstants.apiURL + 'downloadFile.php?eventId=' + this.activatedRoute.snapshot.params['id'] + '&userid=' + sessionStorage.getItem('userId') + '&fileName=' + filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  DownloadholdingDocument(docid) {

    var element = document.createElement('a');
    element.setAttribute('href', GlobalConstants.apiURL + 'downloadholdingDocument.php?id=' + docid);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  /******Event Details Data******/
  getEventDetails(): any {
    if (sessionStorage.getItem('name') || sessionStorage.getItem('userId')) {
      this.http.get(GlobalConstants.apiURL + 'GetDetails.php?id=' + this.activatedRoute.snapshot.params['id'] + '&userId=' + sessionStorage.getItem('userId'), httpOptions).subscribe(response => {
        this.eventDetailsData = response;
        this.eventDetails = this.eventDetailsData.eventDetails;
        // console.log('this.eventDetails', this.eventDetails);
        this.holdingNumberArr = this.eventDetails.holdingNumber.split(',');
        this.clearingSystemArr = this.eventDetails.clearingSystem.split(',');
        this.documentDetails = this.eventDetailsData.documentDetails;
        this.checkType = this.eventDetails.eventType;
        //console.log(response);
      })
    }
    else {
      this.http.get(GlobalConstants.apiURL + 'GetDetails.php?id=' + this.activatedRoute.snapshot.params['id'], httpOptions).subscribe(response => {
        this.eventDetailsData = response;
        this.eventDetails = this.eventDetailsData.eventDetails;
        this.checkType = this.eventDetails.eventType;
        // console.log(response);
      })
    }

  }
  countryList(): any {
    this.http.get(GlobalConstants.apiURL + 'countryList.php', httpOptions).subscribe(response => {
      // console.log(response);
      this.countryDetailsData = response;
      this.countryDetails = this.countryDetailsData.data;
      console.log('Details', this.countryDetails);
      // this.holdingNumberArr = this.eventDetails.holdingNumber.split(',');
    })
  }
  countryCodeList(): any {
    this.http.get(GlobalConstants.apiURL + 'countryCodeList.php', httpOptions).subscribe(response => {
      // console.log(response);
      this.countryCodeDetailsData = response;
      this.countryCodeDetails = this.countryCodeDetailsData.data;
      //console.log('Details', this.countryCodeDetails);
      // this.holdingNumberArr = this.eventDetails.holdingNumber.split(',');
    })
  }
  /******Event HoldingData******/

  getHoldingData(): any {
    this.http.get(GlobalConstants.apiURL + 'holdingList.php?id=' + this.activatedRoute.snapshot.params['id'] + '&userId=' + sessionStorage.getItem('userId'), httpOptions).subscribe(response => {
      this.holdingData = response;
      this.holdingDetailsList = this.holdingData.HoldingDetailsList;
      //console.log(response);
    })
  }

  /******Registration Data POST******/

  postRegisterData(): any {
    let jsonData = new HttpParams()
      .set('name', this.username)
      .set('email', this.email)
      .set('mobile', this.mobile)
      .set('password', this.password);

    this.http.post(GlobalConstants.apiURL + 'signup.php', jsonData).subscribe(response => {
      //console.log(response, 'data');
      //this.getEventDetails();
      location.reload();
    });
  }
  /******Login Details Data******/
  telInputObject(obj, d) {
    alert(d);
  }

  fileext() {
    var t = ""
    var ext = /^.+\.([^.]+)$/.exec(this.holdingDocument);
    return ext == null ? false : true;
  }

  postLoginData(): any {
    let jsonData = new HttpParams()
      .set('email', this.loginemail)
      .set('password', this.loginPassword);

    this.http.post(GlobalConstants.apiURL + 'login.php', jsonData).subscribe(response => {
      //console.log(response, 'data');

      if (response[0] && response[0].status && response[0].status == true) {
        sessionStorage.setItem('name', response[0].name);
        sessionStorage.setItem('userId', response[0].user_id);


      }
      location.reload();

    });
  }
}

interface $ {
  model(): void;
}

