<!-- Start Navbar Area -->
<div class="navbar-area is-sticky {{layoutClass}}">
    <!-- <div class="navbar-area is-sticky"> -->

    <div class="luvion-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img *ngIf="location != '/demo-3'" src="assets/img/klob_logo.webp" alt="i2CapMark" width="300px">
                    <img src="assets/img/klob_logo.webp" alt="i2CapMark" width="300px">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link">Our Services</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/transactions/1" routerLinkActive="active" class="nav-link">Transactions</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>
                    <div class="others-options">                       
                        <a routerlink="/signin" *ngIf="!loggin" routerlinkactive="active" class="login-btn" href="/signin">
                            <i class="flaticon-user"></i> Log In
                        </a>                        
                        <a (click)="doLogout()" *ngIf="loggin" routerlinkactive="active" class="login-btn" href="/signin">
                            <i class="flaticon-user"></i> Logout
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->