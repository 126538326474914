<!-- Start Ready To Talk Area -->
<section class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Ready to talk?</h3>
            <p>We’re ready to answer your questions</p>
            <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
           
        </div>
    </div>
</section>
<!-- End Ready To Talk Area -->
